.products-intro-wrapper {
  display: flex;
  margin-top: 3.75rem;
  margin-bottom: 4.875rem;

  @media only screen and (max-width: $screen-sm-min) {
    flex-direction: column;
    margin-bottom: 0;
    margin-top: 0;
  }
}

.main-title-indent {
  @media only screen and (max-width: $screen-sm-min) {
    margin-bottom: 2.5rem;
  }
}

.products-slogan-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0;

  @media only screen and (max-width: $screen-sm-min) {
    width: 100%;
    font-size: 2rem;
    line-height: 2.125rem;
  }
}

.products-buttons-container,
.products-buttons-container-mobile {
  display: flex;
  justify-content: space-between;
  margin: 0 0 9.875rem 0;

  @media only screen and (max-width: $screen-xs-max) {
    display: none;
  }

  .product-button {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 14.25rem;
    text-decoration: none;

    @media only screen and (max-width: $screen-xs-max) {
      max-width: 9.35rem;
    }

    .product-button-selector {
      position: absolute;
      width: 100%;
      height: 100%;
      max-height: 55%;
      background-color: #ECEFF8;
      border-radius: 1rem;
      z-index: -1;
      transition: max-height .5s ease-in;

      @media only screen and (max-width: $screen-xs-max) {
        max-height: 43%;
      }
    }

    .arrow-right {
      margin-bottom: 1.25rem;
    }

    &:hover {
      .product-button-selector {
        max-height: 100%;
        transition: max-height .5s ease-out;

        @media only screen and (max-width: $screen-xs-max) {
          max-height: 43%;
        }
      }
    }
  }

  .button-title {
    text-align: center;
    line-height: 1.5rem;
    margin: 1.5rem 1.25rem;

    @media only screen and (max-width: $screen-xs-max) {
      margin: 1.5rem 0;
    }
  }

  .product-icon {
    padding: 1rem 2.375rem;

    @media only screen and (max-width: $screen-xs-max) {
      width: 66%;
      padding: 0.625rem 1.5rem;
    }
  }
}

.products-buttons-container-mobile {
  display: none;

  @media only screen and (max-width: $screen-xs-max) {
    display: flex;
    margin: 2.5rem 0 0 0;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .first {
    @media only screen and (max-width: $screen-xs-max) {
      margin-right: 0.5rem;
      margin-bottom: 1.25rem;
    }
  }

  .second {
    @media only screen and (max-width: $screen-xs-max) {
      margin-left: 0.5rem;
      margin-bottom: 1.25rem;
    }
  }
}

.bottom-buttons-container {
  @media only screen and (max-width: $screen-xs-max) {
    display: flex;
    margin: 0 0 3.25rem 0;
    justify-content: center;
  }
}

.product-title-wrapper {
  margin: 0 auto 2.5rem;
  max-width: 86.25rem;

  @media only screen and (max-width: $screen-sm-max) {
    margin: -10rem 0 2rem;
  }
}

.wide-title-container {
  width: 54%;

  @media only screen and (max-width: $screen-sm-max) {
    width: auto;
  }
}

.products-group-wrapper,
.products-images-group-wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 86.25rem;
  margin: 0 auto 7.5rem;

  @media only screen and (max-width: $screen-xs-max) {
    flex-direction: column;
    margin: 0 0 2rem;
  }

  .product-item-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-right: 2rem;

    @media only screen and (max-width: $screen-xs-max) {
      width: auto;
      margin-right: 0;
    }
  }
}

.products-images-group-wrapper {
  margin: 0 auto 2rem;

  @media only screen and (max-width: $screen-xs-max) {
    margin: 0;
  }
}

.product-title {
  font-family: $font-name-ttnorms-medium;
  font-size: 1.25rem;
  line-height: 2rem;
  color: #242240;
  margin-bottom: 1rem;

  @media only screen and (max-width: $screen-xs-max) {
    font-size: 1.125rem;
    line-height: 1.6rem;
    margin-bottom: 0.5rem;
  }
}

.product-price {
  font-family: $font-name-ttnorms;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #9393DF;
  margin-bottom: 1.5rem;

  @media only screen and (max-width: $screen-xs-max) {
    font-size: 1.063rem;
    margin-bottom: 1rem;
  }
}

.product-description {
  font-family: $font-name-ttnorms;
  font-size: 1rem;
  line-height: 1.6rem;
  color: #242240;
  margin: 0 1.5rem 2.5rem 0;

  @media only screen and (max-width: $screen-xs-max) {
    margin: 0 0 2.5rem 0;
  }
}

.product-image,
.product-image-mobile {
  height: 29.75rem;
  border: 1px solid #CCCCCC;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: $screen-xs-max) {
    display: none;
  }

  img {
    width: 50%;
    margin: 2rem;
  }

  .vertical-image {
    width: 122px;
    height: 290px;

    @media only screen and (max-width: $screen-xs-max) {
      width: 60px;
      height: 144px;
    }
  }
}

.product-image-mobile {
  display: none;

  @media only screen and (max-width: $screen-xs-max) {
    display: flex;
    height: auto;
    margin-bottom: 1.5rem;
  }
}