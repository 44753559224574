.landing-footer {
  padding-top: 5.125rem;

  @media only screen and (max-width: $screen-sm-max) {
    padding-top: 0;
  }

  a, p {
    color: $c-grey-450;
    font-size: 1rem;
  }

  .landing-footer-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .social-links-container {
      display: flex;
      align-items: center;
      list-style: none;
      margin: 0;

      .social-link-wrapper {
        padding: 0 1.375rem;
      }

      .social-link {
        outline: 0;

        svg {
          .fraction {
            fill: #7b77d1;
          }

          .background {
            fill: white;
          }
        }
      }
    }

    .landing-copyright-contacts {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      padding-top: 1rem;

      p {
        margin: 0;
        padding-right: 1rem;
      }
    }

    .landing-copyright {
      padding-top: 1rem;
    }
  }

  .container {
    padding-bottom: 0.5rem;

    @media only screen and (max-width: $screen-sm-max) {
      padding-bottom: 1.5rem;
    }
  }
}