// -----------------------------------------------------------------------------
// Header
// -----------------------------------------------------------------------------

.header,
.scroll-menu {
  width: 100%;
  margin: auto;
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: transparent;
  transition: background-color 0.5s ease;

  .container {
    padding: 0;
  }

  .custom-container {
    background-color: #F6F6F6;

    .container {
      padding: 0;

      .contact-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0;
        padding-top: 0;
        padding-bottom: 0;
        height: 3.8rem;
        list-style: none;

        @media only screen and (max-width: $screen-sm) {
          padding: 0 1.25rem 0 1.25rem;
          justify-content: space-between;
        }

        .social-link {
          display: flex;
          align-items: center;

          .intro-subtitle {
            margin: 0;
            font-family: "Sofia-Pro-Regular", "Open Sans";
            font-size: 0.875rem;
            color: #393853;
            padding-left: 0.5rem;

            @media only screen and (max-width: $screen-sm) {
              font-size: 0.75rem;
            }
          }
        }

        li:last-child {
          padding-left: 2rem;
        }
      }
    }
  }

  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3.125rem 1.875rem;
    transition: padding 0.5s ease;

    @media only screen and (max-width: $screen-xs-max) {
      padding: 2.188rem 1.25rem;
    }
  }

  .head-menu {
    display: flex;
    justify-content: space-between;
  }

  .connect-button {
    .button-primary {
      height: 61px;
      width: 228px;
      box-sizing: border-box;
      border: 2px solid #EF5A8682;
      border-radius: 8px;
      background-color: $c-white;
      box-shadow: none;

      p {
        font-family: "Sofia-Pro-SemiBold","Open Sans";
        color: #F7A9BF;
      }
    }

    @media only screen and (max-width: $screen-sm) {
      display: none;
    }
  }

  .connect-button:hover {
    .button-primary {
      p {
        color: $c-white;
      }
    }
  }

  .mobile-nav-container {
    @media only screen and (max-width: $screen-sm-max) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
    }
  }

  .menu {
    display: flex;
    list-style: none;
    margin: 0.4rem 0.1rem;
    padding: 0;

    &.desktop-menu {
      @media only screen and (max-width: $screen-sm-max) {
        display: none;
      }
    }
  }

  .main-menu {
    @media only screen and (max-width: $screen-sm-max) {
      justify-content: space-between;
      width: 100%;
    }
  }

  .logo-wrapper {
    display: flex;
    align-items: center;
    margin-right: 1.5rem;

    @media only screen and (max-width: $screen-xs-max) {
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  .logo {
    display: block;
    position: relative;
    flex: 0 0 auto;
    width: 6.938rem;
    height: 1.438rem;
    overflow: hidden;
    border: none;

    svg {
      position: absolute;
      top: -0.063rem;
      left: 0;
    }
  }

  .toggle-menu {
    display: flex;
    z-index: 10;

    @media only screen and (max-width: $screen-xs-max) {
      position: absolute;
      right: 1.25rem;
      bottom: 1.25rem;
    }
  }

  .toggle-menu-icon {
    @media only screen and (min-width: $screen-md-min) {
      display: none;
    }

    position: relative;
  }

  .nav-link-wrapper,
  .nav-link-wrapper-mobile {
    padding: 0.2rem 1rem;
    z-index: 100;
    display: flex;
    align-items: center;

    @media only screen and (max-width: $screen-md-max) {
      padding: 0.688rem 0.6rem;
    }
  }

  .nav-link-wrapper-mobile {
    padding: 0.938rem 1.25rem;
  }

  .link {
    font-family: $font-sofia-pro-semi-bold;
    color: $c-grey-500;
    opacity: 0.9;

    &:hover {
      color: #9393DF;
      opacity: 1;
    }
  }

  .lang-buttons-container {
    display: flex;

    button:last-child {
      margin-right: 0;
    }

    @media only screen and (max-width: $screen-sm-max) {
      display: none;
    }
  }

  .link-mobile {
    font-family: $font-sofia-pro-semi-bold;
    font-size: 1.125rem;
    font-weight: bold;
    text-transform: uppercase;
    color: $c-grey-500;
    opacity: 0.9;
    padding: 0.625rem;
    text-decoration: none;

    @media only screen and (max-width: $screen-sm-max) {
      padding: 0.625rem 0;
    }
  }

  .expanded-menu-container {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: $c-white;
    overflow: auto;
    z-index: 100;

    &.hidden {
      display: none;
    }

    .expanded-menu {
      width: 100%;
      max-height: 100%;
    }

    .menu-title {
      font-family: $font-sofia-pro-semi-bold;
      font-size: 9.563rem;
      font-weight: bold;
      color: $c-grey-500;
      opacity: 0.02;
      text-transform: uppercase;
      text-align: center;
      position: absolute;
      top: 19%;
      line-height: 13.125rem;

      @media only screen and (max-width: $screen-xs-max) {
        font-size: 5.938rem;
      }
    }

    .menu {
      flex-direction: column;

      .nav-link-wrapper {
        margin-top: 2.8125rem;
        margin-bottom: 2.8125rem;

        @media only screen and (max-width: $screen-sm-max) {
          padding: 0.688rem 1.25rem;
          margin-top: 3rem;
          margin-bottom: 1.875rem;
          width: auto;
          position: sticky;
          bottom: 0;
        }
      }

      .link {
        font-size: 1.375rem;
        font-weight: 600;

        @media only screen and (max-width: $screen-xs-max) {
          font-size: 0.875rem;
        }
      }
    }
  }
}

.head-mobile-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.188rem 1.25rem;
  box-shadow: 0px 3px 10px #21212126;
}

.scroll-menu {
  background-color: #FFFFFF !important;
  box-shadow: 0px 3px 10px #21212126;
  transition: background-color 0.5s ease;

  @media only screen and (max-width: $screen-md-max) {
    box-shadow: 0px 3px 10px #21212126;
  }

  .nav-container {
    padding: 1.1rem 1.875rem;
    transition: padding 0.5s ease;

    @media only screen and (max-width: $screen-md-max) {
      padding: 1.25rem;
    }
  }
}
