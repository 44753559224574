// -----------------------------------------------------------------------------
//  Fonts
// -----------------------------------------------------------------------------
//    01. Base Fonts
//    02. Font TT Norms
//    03. Font Sofia Pro
// -----------------------------------------------------------------------------

//    01. Base Fonts
$default-fonts: "Open Sans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;

//    02. Font TT Norms
$font-name-ttnorms: 'TTNorms-Regular';
$font-name-ttnorms-bold: 'TTNorms-Bold';
$font-name-ttnorms-bold-italic: 'TTNorms-BoldItalic';
$font-name-ttnorms-italic: 'TTNorms-Italic';
$font-name-ttnorms-medium: 'TTNorms-Medium';
$font-name-ttnorms-medium-italic: 'TTNorms-MediumItalic';

@font-face {
  font-family: $font-name-ttnorms;
  src:
    url('/assets/fonts/TTNorms/TTNorms-Regular.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: $font-name-ttnorms-bold;
  src:
    url('/assets/fonts/TTNorms/TTNorms-Bold.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: $font-name-ttnorms-bold-italic;
  src:
    url('/assets/fonts/TTNorms/TTNorms-BoldItalic.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: $font-name-ttnorms-italic;
  src:
    url('/assets/fonts/TTNorms/TTNorms-Italic.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: $font-name-ttnorms-medium;
  src:
          url('/assets/fonts/TTNorms/TTNorms-Medium.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: $font-name-ttnorms-medium-italic;
  src:
          url('/assets/fonts/TTNorms/TTNorms-MediumItalic.ttf') format('truetype');
  font-display: swap;
}

$font-ttnorms: $font-name-ttnorms, $default-fonts;
$font-ttnorms-bold: $font-name-ttnorms-bold, $default-fonts;
$font-ttnorms-bold-italic: $font-name-ttnorms-bold-italic, $default-fonts;
$font-ttnorms-italic: $font-name-ttnorms-italic, $default-fonts;
$font-ttnorms-medium: $font-name-ttnorms-medium, $default-fonts;
$font-ttnorms-medium-italic: $font-name-ttnorms-medium, $default-fonts;

//    03. Font Sofia Pro
$font-name-sofia-pro: 'Sofia-Pro-Regular';
$font-name-sofia-pro-bold: 'Sofia-Pro-Bold-Az';
$font-name-sofia-pro-semi-bold: 'Sofia-Pro-SemiBold';

@font-face {
  font-family: $font-name-sofia-pro;
  src:
    url('/assets/fonts/SofiaPro/Sofia-Pro-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: $font-name-sofia-pro-bold;
  src:
    url('/assets/fonts/SofiaPro/Sofia-Pro-Bold-Az.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: $font-name-sofia-pro-semi-bold;
  src:
    url('/assets/fonts/SofiaPro/Sofia-Pro-SemiBold.woff2') format('woff2');
  font-display: swap;
}

$font-sofia-pro: $font-name-sofia-pro, $default-fonts;
$font-sofia-pro-bold: $font-name-sofia-pro-bold, $default-fonts;
$font-sofia-pro-semi-bold: $font-name-sofia-pro-semi-bold, $default-fonts;
