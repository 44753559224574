.blog-group-wrapper,
.blog-images-group-wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 86.25rem;
  margin: 0 auto 7rem;

  @media only screen and (max-width: $screen-sm-max) {
    flex-direction: column;
    margin: 0 0 2rem;
  }

  &.last-item {
    @media only screen and (max-width: $screen-sm-max) {
      margin: 0 0 9.375rem;
    }
  }

  .blog-item-container {
    display: flex;
    flex-direction: column;
    width: 44%;

    @media only screen and (max-width: $screen-sm-max) {
      width: auto;
      margin: 0 1.25rem;
    }
  }

  .button-gateways-map {
    @media only screen and (max-width: $screen-md) {
      width: auto;
    }
  }
}

.blog-images-group-wrapper {
  margin: 0 auto 2rem;

  @media only screen and (max-width: $screen-sm-max) {
    margin: 0;
  }
}

.blog-image {
  height: 25.2rem;
  border: 1px solid #CCCCCC;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  img {
    border-radius: 1rem;
  }

  @media only screen and (max-width: $screen-sm-max) {
    display: none;
  }
}

.blog-image-mobile {
  display: none;

  @media only screen and (max-width: $screen-sm-max) {
    display: flex;
    height: auto;
    margin: 6.25rem 0 1.5rem;
  }

  &.first {
    @media only screen and (max-width: $screen-sm-max) {
      margin: 0 0 1.5rem;
    }
  }
}

.article-date {
  font-size: 1rem;
  color: #9595A1;
  margin-bottom: 1rem;

  @media only screen and (max-width: $screen-sm-max) {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
}

.before-button {
  margin-bottom: 2rem;
}

.block-wrapper {
  background-image: url("/assets/images/news/sensors_for_individuals_without_line.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 45%;
}

.first-block {
  @media only screen and (max-width: $screen-md) {
    margin-top: 2rem;
  }
}