.breadcrumb,
.breadcrumb-without-wrapper {
  width: 100%;
  max-width: 86.25rem;
  margin: auto auto 2rem;
  color: #999999;

  ul {
    display: block;
    margin: 0;
    list-style: none;
    overflow-y: auto;

    @media only screen and (max-width: $screen-sm-max) {
      padding: 0;
    }

    .page {
      display: inline;
      position: relative;
      padding-right: 0.5rem;
      color: #9595A1;
      font-size: .688rem;
      text-transform: uppercase;

      &:first-child {
        padding-left: 0;
      }

      &:before {
        display: none;
      }

      &.active {
        color: #9595A1;

        &:after {
          display: none;
        }
      }

      a {
        color: $c-deep-purple;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.breadcrumb-without-wrapper {
  @media only screen and (max-width: $screen-sm-max) {
    width: auto;
    padding: 0 1.25rem;
  }

  @media only screen and (min-width: $screen-md) {

    ul {
      margin-inline-start: 5px;
    }
  }
}