html {
  height: 100%;
  scroll-behavior: smooth;

  body {
    height: 100%;
    min-width: 20rem;
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-family: $font-sofia-pro;
    font-weight: normal;
    background-color: $c-white;
    zoom: 85%;

    @media only screen and (max-width: $screen-md-min) {
      zoom: normal;
    }
  }

  &.safari {
    body {
      zoom: 100%;
    }
  }

  &.chrome {
    body {
      @media only screen and (min-width: 1550px) {
        zoom: 100%;
      }
    }
  }

  &.opr {
    body {
      @media only screen and (min-width: 1550px) {
        zoom: 100%;
      }
    }
  }

  body:lang(en) {
      font-variant-ligatures: no-common-ligatures;
  }

  main {
    margin-top: 11.25rem;

    @media only screen and (max-width: $screen-sm-max) {
      margin-top: 7rem;
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.container {
  max-width: 90rem;
  padding: 1.875rem;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;

  @media only screen and (max-width: $screen-sm-max) {
    width: auto;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.with-line {
  border-top: 1px solid #CCCCCC;
  padding-top: 8.25rem;
  padding-bottom: 5rem;

  @media only screen and (max-width: $screen-sm-max) {
    padding: 5rem 0 2rem;
    margin: 0 1.25rem;
  }
}

.with-line-mobile {
  border-top: transparent;

  @media only screen and (max-width: $screen-sm-max) {
    border-top: 1px solid #CCCCCC;
    padding: 5rem 0 2rem;
    margin: 3rem 1.25rem 0;
  }
}

.about-footer {
  margin-bottom: 3rem;

  @media only screen and (max-width: $screen-xs-max) {
    margin-bottom: 0rem;
  }
}

.show-mobile-only {
  @media only screen and (min-width: $screen-sm-min) {
    display: none;
  }
}

.tablet-hidden {
  @media only screen and (max-width: $screen-md-max) {
    display: none;
  }
}

.link {
  font-size: 0.875rem;
  font-weight: bold;
  padding: 0.625rem 0;
  border-bottom: 0.065rem solid transparent;
  text-decoration: none;
  text-transform: uppercase;
  outline: 0;

  &:hover,
  &:focus,
  &:active {
    border-bottom-color: #9393DF;
    text-decoration: none;
  }
}

.page-content {
  position: relative;
  margin: 11.25rem auto 4.375rem auto;
  padding: 0;

  @media only screen and (max-width: 768px) {
    margin-top: 7rem;
    margin-right: 1.875rem;
    margin-left: 1.875rem;
  }

  @media only screen and (max-width: 576px) {
    margin-right: 0;
    margin-left: 0;
  }

  @media only screen and (max-width: 425px) {
    margin-bottom: 0;
  }

  .clock-icon {
    height: .938rem;
    width: auto;
    margin: 0 .313rem 0 0;
  }
}

.wrapper {
  max-width: 86.25rem;
  margin: 0rem auto;
  min-height: 51.5vh;

  @media only screen and (max-width: 800px) {
    padding: 0 1.25rem;
    margin: 0;
  }
}

// Fonts styles

h1 {
  font-family: $font-name-ttnorms-medium;
  font-size: 4.18rem;
  letter-spacing: 0px;
  line-height: 80px;
  color: #242240;
  margin: 0;

  &.firefox {
    @media only screen and (min-width: $screen-md) {
      font-size: 3.5rem;
      line-height: 60px;
    }
    @media only screen and (min-width: $screen-xl) {
      font-size: 4.18rem;
      line-height: 80px;
    }
  }

  &.safari {
    @media only screen and (min-width: $screen-md) {
      font-size: 3.5rem;
      line-height: 60px;
    }
    @media only screen and (min-width: $screen-xl) {
      font-size: 4.18rem;
      line-height: 80px;
    }
    @media only screen and (min-width: $screen-lg) {
      font-size: 2.5rem;
      line-height: 50px;
    }
    @media only screen and (min-width: 1250px) {
      font-size: 3.5rem;
      line-height: 60px;
    }
  }

  &.dark {
    color: #242240;
  }

  &.purple {
    font-family: $font-name-ttnorms-medium-italic;
    font-weight: 200;
    color: #9393DF;
  }

  @media only screen and (max-width: $screen-sm-min) {
    font-size: 2rem;
    line-height: 2.125rem;
  }
}

.blog-title {
  margin-inline-start: -5px;

  @media only screen and (max-width: $screen-md) {
    margin-inline-start: -2px;
  }
}

span {
  &.dark {
    color: #242240;
  }

  &.purple {
    font-family: $font-name-ttnorms-medium-italic;
    font-weight: 200;
    color: #9393DF;
  }
}

h2 {
  font-family: $font-name-ttnorms-medium;
  font-size: 2.5rem;
  letter-spacing: 0px;
  color: #242240;
  margin-bottom: 0;

  @media only screen and (max-width: $screen-sm-max) {
    font-size: 1.5rem;
  }

  span {
    &.italic {
      font-family: $font-name-ttnorms-medium-italic;
    }
  }
}

.second-level-title {
  font-size: 3.25rem;
  line-height: 3.625rem;

  @media only screen and (max-width: $screen-sm-max) {
    font-size: 1.75rem;
    line-height: 1.875rem;
  }
}

h3 {
  font-family: $font-name-ttnorms-medium;
  font-size: 1.25rem;
  letter-spacing: 0px;
  color: #242240;

  @media only screen and (max-width: $screen-sm-min) {
    font-size: 1.125rem;
  }
}

.main-text {
  font-family: $font-name-ttnorms;
  font-size: 1rem;
  line-height: 1.6rem;
  color: #242240;
}

// Developers page

.solutions-intro-container {
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
  overflow: hidden;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;

  &.developers {
    background-image: url("/assets/images/developers/intro.svg");
  }

  &.water-utilities {
    background-image: url("/assets/images/water-utilities/intro.svg");
  }

  &.manufactures {
    background-image: url("/assets/images/productions/intro.svg");
  }

  @media only screen and (max-width: $screen-sm-min) {
    justify-content: flex-start;
    background: none;
    margin-bottom: 3.5rem;

    &.developers,
    &.water-utilities,
    &.manufactures {
      background: none;
    }
  }
}

.solutions-text-container {
  min-width: 90rem;
  margin-bottom: 35.825rem;

  @media only screen and (max-width: $screen-sm-min) {
    min-width: auto;
    margin-bottom: 0;
  }
}

.solutions-title-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: $font-name-ttnorms-medium;
  margin: 1.75rem 0 1rem 2.7rem;

  @media only screen and (max-width: $screen-sm-min) {
    width: auto;
    margin: 0 1.25rem;
  }

  &.text-container {
    margin: 1.75rem 0 1rem 1.5rem;

    @media only screen and (max-width: $screen-sm-min) {
      margin: 0 1.25rem;
    }
  }

  .title {
    margin-bottom: 1.5rem;

    @media only screen and (max-width: $screen-sm-min) {
      font-size: 2rem;
      line-height: 2.1rem;
    }
  }

  .description {
    font-family: $font-name-ttnorms;
    font-size: 1.25rem;
    line-height: 2rem;
    color: #242240;
    margin-bottom: 1.5rem;
    max-width: 42rem;

    @media only screen and (max-width: $screen-sm-min) {
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }
}

.solutions-intro-image {
  display: none;
  position: absolute;
  top: 168px;
  right: 592px;
  height: auto;
  max-width: 1600px;
  z-index: -1;

  @media only screen and (max-width: $screen-sm-min) {
    display: none;
  }
}

.solutions-intro-image-mobile {
  display: none;

  @media only screen and (max-width: $screen-sm-min) {
    display: flex;
    margin: 4rem auto 2rem;
  }
}

.solutions-list-container {
  display: flex;
  margin: auto;
  max-width: 86.25rem;

  @media only screen and (max-width: $screen-sm-max) {
    flex-direction: column;
  }
}

.solutions-list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media only screen and (max-width: $screen-sm-max) {
    align-items: flex-start;
  }

  .list-item {
    width: 75%;
    display: flex;
    flex-direction: column;
    margin-bottom: 11.25rem;

    @media only screen and (max-width: $screen-sm-max) {
      width: auto;
      margin-bottom: 5.5rem;
    }

    .title {
      margin-bottom: 1.5rem;

      @media only screen and (max-width: $screen-sm-max) {
        margin: 0 1.25rem 1rem;
      }
    }

    .title-questions {
      margin-bottom: 0rem;

      @media only screen and (max-width: $screen-sm-max) {
        margin: 0 1.25rem 1rem;
      }
    }

    .description {
      @media only screen and (max-width: $screen-sm-max) {
        margin: 0 1.25rem;
      }
    }

    img {
      border-radius: 1rem;
      margin-top: 2rem;

      @media only screen and (max-width: $screen-sm-max) {
        border-radius: 0;
      }
    }
  }
}

.solutions-menu-container,
.solutions-menu-container-mobile {
  position: -webkit-sticky;
  position: sticky;
  top: 7.9rem;
  display: flex;
  flex-direction: column;
  min-height: 2rem;
  background: white;
  box-shadow: 0px 0px 1.25rem #00000019;
  border-radius: 1rem;
  padding: 2rem;
  margin: -32rem auto 11.5rem;
  max-height: 22rem;
  z-index: 25;

  @media only screen and (max-width: $screen-sm-max) {
    display: none;
  }

  .menu-title {
    font-family: $font-sofia-pro-semi-bold;
    font-weight: 700;
    font-size: 1.625rem;
    line-height: 2rem;
    text-transform: uppercase;
    color: #242240;
    margin-bottom: 1.25rem;

    @media only screen and (max-width: $screen-sm-max) {
      font-size: 1rem;
      margin-bottom: 0;
    }
  }

  .solutions-menu-item {
    display: flex;
    align-items: center;
    font-family: $font-sofia-pro-semi-bold;
    font-weight: 700;
    font-size: 1rem;
    text-decoration: none;
    text-transform: uppercase;
    color: $c-grey-450;
    cursor: pointer;
    margin: 0.625rem 8.5rem 0.625rem 0;
    width: 17.5rem;

    div {
      display: none;
    }

    &:hover {
      text-decoration: none;
    }

    &.active {
      color: #F37EA1;

      div {
        display: block;
        width: 0.375rem;
        height: 0.375rem;
        background-color: #F37EA1;
        border-radius: 50%;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 0.5rem;
      }
    }
  }
}

.solutions-menu-container-mobile {
  display: none;
  top: 4.7rem;

  @media only screen and (max-width: $screen-sm-max) {
    display: flex;
    justify-content: center;
    border-radius: 0;
    background-color: #F2F2F2;
    box-shadow: none;
    padding: 1.25rem;
    margin: 0 0 3rem 0;
    width: auto;
  }

  .solutions-menu-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    background: transparent;
    padding: 0;

    .arrow {
      background-image: url('/assets/images/arrow_down.svg');
      background-repeat: no-repeat;
      background-size: cover;
      transform: rotate(90deg);
      height: 7px;
      width: 13px;
      color: #242240;
    }
  }

  .solutions-menu-item {
    display: none;
  }

  &.open {
    .solutions-menu-button {
      margin-bottom: 1rem;

      .arrow {
        transform: rotate(180deg);
      }
    }

    .solutions-menu-item {
      display: flex;
      align-items: center;
      font-family: $font-sofia-pro-semi-bold;
      font-weight: 700;
      font-size: 0.75rem;
      text-decoration: none;
      text-transform: uppercase;
      color: $c-grey-450;
      cursor: pointer;
      margin: 1.375rem 0;
      width: auto;
    }
  }
}

.solutions-clients-wrapper {
  margin: 0 auto 14.625rem;
  max-width: 86.25rem;

  @media only screen and (max-width: $screen-sm-max) {
    margin: 0 1.25rem 6.25rem;
  }

  .clients-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0;

    @media only screen and (max-width: $screen-sm-max) {
      flex-wrap: wrap;
      margin: 0;
    }

    img {
      max-width: 12.5rem;
      max-height: 6.25rem;

      @media only screen and (max-width: $screen-sm-max) {
        max-width: 8.25rem;
        max-height: 5.125rem;
        margin: 2rem 3rem 2rem 0;
      }
    }

    .first-type-icon {
      @media only screen and (max-width: $screen-sm-max) {
        margin: 2rem 1.82rem 2rem 0;
      }
    }

    .second-type-icon {
      @media only screen and (max-width: $screen-sm-max) {
        margin: 2rem 3rem 2rem 0;
      }
    }

    .third-type-icon {
      @media only screen and (max-width: $screen-sm-max) {
        margin: 2rem 3.3rem 2rem 0;
      }
    }
  }
}

.contact-us-form {
  .form-title {
    font-family: $font-ttnorms-bold;
    font-size: 2.25rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    color: $c-grey-500;
    line-height: 3.375rem;
    letter-spacing: 0;
    margin-bottom: 2.5rem;
    padding: 0;

    @media only screen and (max-width: $screen-xs-max) {
      font-size: 1.25rem;
      margin-bottom: 2rem;
      line-height: 1.75rem;
    }
  }

  .form-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 5rem;

    .form-container {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      @media only screen and (max-width: $screen-sm-max) {
        flex-direction: column;
      }

      .button {
        position: relative;
        left: -0.938rem;

        @media only screen and (max-width: $screen-sm-max) {
          position: static;
        }
      }
    }
  }
}

// How it works page

.section-description {
  font-family: $font-ttnorms;
  font-size: 1.25rem;
  color: $c-grey-300;
  margin: 3.438rem 15rem;
  line-height: 2.25rem;
  text-align: center;

  @media only screen and (max-width: $screen-md-min) {
    margin: 3.438rem 2rem;
  }

}

.work-line {
  border-left: 2px solid #ECEFF8;
  padding-left: 70px;
  position: relative;
  top: 12px;
  height: 910px;

  @media only screen and (max-width: $screen-sm-min) {
    display: none;
  }
}

// FAQ page

.faq-wrapper {
  max-width: 55rem;
}

.faq-page-wrapper {
  max-width: 86.25rem;
  margin: 0 auto 6.5rem;

  @media only screen and (max-width: $screen-xs-max) {
    margin: 0 0 4.5rem;
  }
}

.faq-image {
  box-shadow: 5px -2px 20px 1px rgba(92, 92, 174, 0.1);
  margin-top: 1.5rem;
}

// Gateways map

.gateway-title-container {
  display: flex;
  justify-content: center;
  margin-bottom: 3.5rem;

  @media only screen and (max-width: $screen-sm-min) {
    margin-bottom: 2.5rem;
  }
}

.gateway-count {
   color: white;
   background-color: #7b77d1;
   padding: 0 10px;
}

.google-map-wrapper {
  border: 1px solid rgba(196, 196, 196, 0.3);
  box-sizing: border-box;
  border-radius: 10px;
  margin: 0 auto 120px;
  display: block;
  width: 1380px;
  height: 800px;

  .google-map {
    display: flex;
    border-radius: 10px 10px 0 0 ;
    width: 1379px;
    height: 700px;

    @media only screen and (max-width: $screen-lg-min) {
      width: 100%;
    }

    @media only screen and (max-width: $screen-sm-min) {
      width: 100%;
      height: 514px;
      border-radius: 0;
    }
  }

  .gateways-map-legend {
    display: flex;
    justify-content: space-around;
    margin: 25px 65px;

    .legend-area {
      display: flex;
      align-items: center;

      @media only screen and (max-width: $screen-sm-min) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    p {
      margin: 0;
    }

    .reception-name {
      font-family: $font-name-ttnorms;
      display: flex;
      align-items: center;
      width: 220px;

      @media only screen and (max-width: $screen-sm-min) {
        margin: 10px;
      }
    }

    @media only screen and (max-width: $screen-sm-min) {
      flex-direction: column;
      margin: 20px;
    }
  }

  @media only screen and (max-width: $screen-lg-min) {
    width: 100%;
  }

  @media only screen and (max-width: $screen-sm-min) {
    height: auto;
    border: none;
    margin: 0 auto;
  }
}

.dividing-line {
  border-top: 1px solid $c-grey-500;
  width: 100%;
  margin-bottom: 6rem;
}

// Contacts page

.contacts-page-wrapper {
  max-width: 86.25rem;
  margin: auto;

  .copyright-link {
    color:#242240;
  }

  @media only screen and (max-width: $screen-sm-max) {
    margin: 0 1.25rem;
  }

  .contact-blocks-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 5rem;

    @media only screen and (max-width: $screen-sm-max) {
      margin-top: 3.5rem;
    }

    .contacts-title {
      font-size: 1.31rem;
      color: #242240;
      margin-bottom: 2rem;

      @media only screen and (max-width: $screen-sm-max) {
        font-size: 1.125rem;
      }
    }
  }

  .contact-block {
    display: flex;
    justify-content: space-between;
    padding-bottom: 4.06rem;

    @media only screen and (max-width: $screen-sm-max) {
      flex-direction: column;
      padding-bottom: 1.56rem;

      p {
        margin-bottom: 1rem;
      }

      .contact-block-item {
        margin-bottom: 1rem;
      }

      .contact-block-item:last-child {
        margin-bottom: 2.9rem;
      }
    }

    .contact-block-item {
      max-width: 23rem;

      p {
        color: #242240;
        font-family: $font-name-ttnorms;
      }
    }
  }

  .products-slogan-wrapper {
    h1 {
      text-transform: uppercase;
      color: #242240;
    }
  }
}

.separator {
  display: block;
  max-width: 86.25rem;
  border: 0;
  border-top: 1px solid #CCCCCC;
  margin: auto;

  @media only screen and (max-width: $screen-sm-max) {
    display: none;
  }
}

.contact-form-container {
  max-width: 86.25rem;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5rem;

  @media only screen and (max-width: $screen-sm-max) {
    flex-direction: column;
    margin: 0 1.25rem;
  }

  .left-contact-container {
    width: 46%;
    padding-top: 5rem;

    @media only screen and (max-width: $screen-sm-max) {
      padding-top: 0;
      width: 100%;

      h3 {
        margin-bottom: 1.5rem;
      }
    }

    .form-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .more-questions-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .questions-input-container {
          margin-bottom: 2rem;

          @media only screen and (max-width: $screen-xs-max) {
            width: 100%;
          }

          .error,
          .error:hover,
          .error:focus {
            color: red;
            border-color: red;
          }

          label {
            display: block;
          }
        }

        .middle-container {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          width: 280px;

          @media only screen and (max-width: $screen-xs-max) {
            width: 100%;
          }
        }

        .more-questions-input,
        .more-questions-input-message {
          font-family: $font-sofia-pro;
          font-size: 1rem;
          padding: 0.938rem 1.75rem 0.5rem 0;
          color: $c-grey-600;
          min-width: 280px;
          box-sizing: border-box;
          border-bottom: 1px solid #CCCCCC;
          border-top: none;
          border-left: none;
          border-right: none;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          outline: none;

          @media only screen and (max-width: $screen-xs-max) {
            min-width: 100%;
            margin-left: 0;
            padding: 2px 0 8px 0;
          }

          &::placeholder {
            color: $c-grey-600;
            font-size: 1rem;

          }

          &:hover,
          &:focus,
          &:active {
            border-color: $c-grey-550;
          }
        }

        .more-questions-input-message {
          min-width: 100%;
          height: 10rem;
          margin-top: 1rem;
          padding: 1rem;
          resize: none;
          border: 1px solid #CCCCCC;
          border-radius: 0.5rem;

          @media only screen and (max-width: $screen-xs-max) {
            min-width: 100%;
            margin-left: 0;
            margin-top: 0;
            padding: 1rem;
          }
        }
      }

      .send-button-container {
        margin-top: 3rem;

        @media only screen and (max-width: $screen-xs-max) {
          margin-top: 2.5rem;
          margin-bottom: 0.5rem;
          width: 100%;
        }

        .button {
          box-sizing: border-box;
          width: 16rem;

          @media only screen and (max-width: $screen-xs-max) {
            width: 100%;
          }
        }
      }
    }
  }

  .right-contact-container {
    display: flex;
    justify-content: space-between;
    width: 48%;
    padding-top: 5rem;

    @media only screen and (max-width: $screen-xs-max) {
      width: 100%;
      flex-direction: column;

      h3 {
        margin-bottom: 22px;
      }

      p {
        margin-bottom: 2rem;
      }
    }

    .contact-info-blocks-container {
      .contact-info-block {
        p {
          font-family: $font-name-ttnorms;
          color: #242240;
        }
      }
    }

    .contact-certificate-image-wrapper {
      @media only screen and (max-width: $screen-sm-max) {
        margin: auto;
        padding-top: 0.5rem;
      }

      .contact-certificate-image {
        width: 367px;
        height: auto;
        border: 1px solid #ECEFF8;
        border-radius: 8px;

        &:hover{
          cursor: pointer;
        }

        @media only screen and (max-width: $screen-sm-max) {
          margin-top: 0;
          margin-left: 0;
        }
      }
    }
  }
}

.google-map-container {
  display: flex;
  justify-content: space-between;
  padding-top: 5rem;
  padding-bottom: 8.75rem;
  max-width: 86.25rem;
  margin: auto;

  @media only screen and (max-width: $screen-sm-min) {
    padding-top: 0.5rem;
    padding-bottom: 6.25rem;
    margin: 0 1.25rem;
  }

  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  .left-container {
    display:flex;
    width: 30%;

    @media only screen and (max-width: $screen-sm-min) {
      width: 100%;
    }

    .left-inner-container {
      flex:1;

      h3 {
        font-size: 1.32rem;
        margin-bottom: 2rem;
      }
    }
  }

  .addresses-container {
    overflow: hidden;

    .address-container {
      width: 100%;
      color: #242240;
      overflow: hidden;

      &-label {
        display: flex;
        align-items: center;
        width: fit-content;
        cursor: pointer;
        color: #242240;
        font-family: $font-name-ttnorms-medium;
        font-size: 1.125rem;

        @media only screen and (max-width: $screen-sm-min) {
          font-size: 1rem;
        }

        p {
          margin: 0;
          padding-left: 0.5rem;
        }

        .address-container-image {
          width: 9px;
          height: 9px;
          transform: rotate(90deg);
          transition: all .25s;
        }
      }

      &-content {
        height: 0;
        padding: 0 2.43rem;
        color: #242240;
        transition: height 0.25s ease-out, padding-bottom 0.25s ease;

        p {
          width: fit-content;
          font-family: $font-name-ttnorms;
          font-size: 1rem;
        }
      }

      &-close {
        display: flex;
        justify-content: flex-end;
        padding: 1rem;
        font-size: 0.75rem;
        cursor: pointer;
      }

      input:checked {
        + .address-container-label {
          .address-container-image {
            transform: rotate(270deg);
            padding-left: 0;
          }
        }

        ~ .address-container-content {
          height: auto;
          padding: 1rem 0 2.5rem 2.43rem;
        }
      }
    }
  }

  .contacts-map {
    width: 945px;
    height: 802px;
    border-radius: 1rem;

    @media only screen and (max-width: $screen-sm-min) {
      height: 40rem;
      display: none;
    }
  }
}

// About us page

.about-intro-wrapper {
  display: flex;
  margin-top: 3.75rem;
  margin-bottom: 9.75rem;

  @media only screen and (max-width: $screen-sm-min) {
    flex-direction: column;
    margin-bottom: 0;
    margin-top: 0;
  }
}

.platform-wrapper {
  display: flex;
  margin-bottom: 2rem;

  @media only screen and (max-width: $screen-sm-min) {
    flex-direction: column;
    margin-bottom: 0;
  }
}

.about-titles-texts {
  margin-bottom: 1.68rem;

  @media only screen and (max-width: $screen-sm-min) {
    //font-size: 1.125rem;
    margin-bottom: 1rem;
  }
}

.accounting-card-meter-title {
  margin-bottom: 1rem;
}

.accounting-card-title {
  font-family: $font-name-ttnorms-bold;
  font-size: 1.5rem;
  color: $c-white;
  text-align: center;
  padding: 1.5rem;

  @media only screen and (max-width: $screen-sm-min) {
    font-size: 1.25rem;
    padding-bottom: 0.5rem;
  }
}

.accounting-descriptions {
  text-align: center;
  padding: 0 1rem;
}

.about-slogan-wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: $screen-sm-min) {
    width: 100%;
  }
}

.about-description-wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: $screen-sm-min) {
    width: 100%;
  }

  .grey-border {
    border-top: 1px solid #CCCCCC;
    margin-bottom: 1.68rem;

    @media only screen and (max-width: $screen-sm-min) {
      border: transparent;
    }
  }
}

.about-description {
  line-height: 1.6rem;
  margin-bottom: 3.75rem;
  font-family: $font-name-ttnorms;
  font-size: 1rem;
  color: #242240;
}

.about-intro-image {
  @media only screen and (max-width: $screen-sm-min) {
    display: none;
  }
}

.about-intro-image-mobile {
  display: none;

  @media only screen and (max-width: $screen-sm-min) {
    display: block;
    margin-top: 4rem;
  }
}

.question-container {
  @media only screen and (max-width: $screen-sm-min) {
    margin: 0;

    .services-wrapper {
      .services-list {
        .services-item {
          .services-name-wrapper {
            padding: 1.6rem 1.25rem;
          }

          .main-text {
            padding: 0 1.25rem;
          }
        }
      }
    }
  }
}

.remote-accounting-container {
  background-color: #ECEFF8;
  margin-bottom: 9.75rem;

  @media only screen and (max-width: $screen-sm-max) {
    padding: 2.25rem 0 3.5rem 0;
    margin-bottom: 4.75rem;
  }
}

.remote-accounting-wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 86.25rem;
  margin: auto;

  @media only screen and (max-width: $screen-sm-max) {
    flex-direction: column;
    align-items: center;
  }

  .remote-accounting-card-common,
  .remote-accounting-card {
    width: 16.75rem;
    height: auto;
    padding: 2.5rem 0rem;
    margin: 9.75rem 0rem;
    background-color: $c-white;
    box-shadow: 0px 0px 10px #9393DF80;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: $screen-sm-max) {
      margin: 1.75rem 0rem;
    }

    .accounting-number {
      border: 1px #F37EA1 solid;
      border-radius: 50%;
      font-family: $font-name-ttnorms-bold;
      font-size: 2.125rem;
      color: #F37EA1;
      width: 10.75rem;
      height: 10.75rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2rem;
    }

    .grey-border {
      border-top: 1px solid #CCCCCC;
      margin-bottom: 1.5rem;
      width: 10.125rem;
    }
  }

  .remote-accounting-card-common {
    background-color: #9393DF;
    box-shadow: 0px 3px 6px #9393DF80;
    height: 12.25rem;

    @media only screen and (max-width: $screen-sm-max) {
      margin: 1.75rem 0;
      height: auto;
    }
  }
}

.about-services-title-wrapper {
  margin: auto;
  margin-bottom: 3.75rem;
  max-width: 86.25rem;

  @media only screen and (max-width: $screen-sm-max) {
    margin: 0 1.25rem;
    margin-bottom: 2.5rem;
  }
}

.achievements-title-wrapper {
  margin: auto;
  max-width: 86.25rem;

  @media only screen and (max-width: $screen-sm-max) {
    margin: 0 1.25rem 0.625rem;
  }
}

.services-image-mobile {
  display: none;

  @media only screen and (max-width: $screen-sm-max) {
    border-radius: 0rem;
    display: block;
  }
}

.services-wrapper {
  display: flex;
  margin-bottom: 9.75rem;

  @media only screen and (max-width: $screen-sm-max) {
    margin-bottom: 4.5rem;
  }

  .services-image {
    height: 100%;
    border-radius: 1rem;

    @media only screen and (max-width: $screen-sm-max) {
      border-radius: 0rem;
      display: none;
    }
  }
}

.services-list {
  display: flex;
  flex-direction: column;
  padding-left: 2rem;

  @media only screen and (max-width: $screen-sm-max) {
    padding-left: 0;
  }
}

.services-item,
.services-item-open,
.question-item {
  border-top: 1px solid #CCCCCC;

  .services-name-wrapper {
    padding: 1.6rem 0rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .services-text {
      font-family: $font-name-ttnorms-medium;
      font-size: 1.25rem;
      line-height: 1.5rem;
      color: #242240;
      padding-right: 1.5rem;
      margin: 0;

      @media only screen and (max-width: $screen-sm-max) {
        line-height: 1.5rem !important;
      }
    }

    .close-button {
      height: 32px;
      width: 32px;
      border: none;
      background-color: transparent;
      background-image: url("/assets/images/about/plus.svg");
      background-repeat: no-repeat;
      background-position: right;
      padding-left: 2rem;
      margin-left: 1.5rem;
      max-height: 50px;
      transition: max-height 0.5s ease-in;
      cursor: pointer;

      @media only screen and (max-width: $screen-sm-max) {
        background-image: url("/assets/images/about/plus_mobile.svg");
        height: 24px;
        width: 24px;
        padding-left: 2rem;
      }
    }
  }

  .services-description {
    margin-bottom: 0;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.25s ease-out, margin-bottom 0.25s ease;
  }
}

.services-item-open {
  .services-name-wrapper {
    .close-button {
      cursor: pointer;
      max-height: 2px;
      transition: max-height 0.5s ease-out;
    }
  }

  .services-description {
    margin-bottom: 1.5rem;
    max-height: 550px;
    transition: max-height 0.5s ease-in, margin-bottom 0.5s ease;
  }
}

.achievements-container {
  background-color: #ECEFF8;
  padding: 9.75rem 0;
  margin-bottom: 9.75rem;

  @media only screen and (max-width: $screen-sm-max) {
    padding: 2.75rem 0;
    margin-bottom: 4.75rem;
  }
}

.achievements-wrapper {
  max-width: 86.25rem;
  margin: auto;
}

.achievements-years-wrapper {
  display: flex;

  @media only screen and (max-width: $screen-sm-max) {
    flex-direction: column;
  }
}

.achievement-time-stamp-container,
.achievement-time-stamp-container-second {
  display: flex;
  flex-direction: column;
  width: 50%;
  position: relative;

  &:before {
    @media only screen and (max-width: $screen-sm-max) {
      content: "";
      width: 1.5rem;
      margin-left: -1.5em;
      border-top: 1px #707070 solid;
      position: absolute;
      top: 72px;
    }
  }

  @media only screen and (max-width: $screen-sm-max) {
    width: auto;
    padding-left: 1.5rem;
  }

  .achievement-date {
    font-family: $font-name-ttnorms-medium;
    font-size: 10rem;
    letter-spacing: 0px;
    color: transparent;
    -webkit-text-stroke: 2px #9393DF;

    @media only screen and (max-width: $screen-sm-max) {
      font-size: 8.125rem;
      line-height: 8.125rem;
      padding-left: 1rem;
      margin-bottom: 2rem;
    }
  }

  .achievement-time-stamp,
  .achievement-time-stamp-second {
    border: 1px solid #707070;
    height: 1.5rem;
    border-top: transparent;
    margin-bottom: 2rem;

    @media only screen and (max-width: $screen-sm-max) {
      display: none;
    }
  }

  .achievement-time-stamp-second {
    border-left: transparent;
  }

  .achievements_list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 22.375rem;

    @media only screen and (max-width: $screen-sm-max) {
      flex-wrap: nowrap;
      max-height: 50rem;
      padding: 0 1.25rem;
    }

    .achievement-item {
      margin-right: 2.5rem;
      margin-bottom: 1.5rem;
      width: 46%;

      @media only screen and (max-width: $screen-sm-max) {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
}

.achievement-time-stamp-container-second {
  &:after {
    @media only screen and (max-width: $screen-sm-max) {
      content: "";
      width: 1.5rem;
      margin-left: -1.5em;
      border-top: 1px #707070 solid;
      position: absolute;
      bottom: 0;
    }
  }
}

.achievement-mobile-wrapper {
  display: flex;
  list-style: none;
  margin: 0;

  .achievement-time-stamp-mobile,
  .achievement-time-stamp-mobile-second {
    display: none;

    @media only screen and (max-width: $screen-sm-max) {
      display: block;
      border: 1px solid #707070;
      height: 100%;
      border-right: transparent;
      margin-top: 4.5rem;
      margin-left: 1.25rem;
    }
  }

  .achievement-time-stamp-mobile-second {
    @media only screen and (max-width: $screen-sm-max) {
      margin-top: 0;
      border-top: transparent;
      height: 50%;
    }
  }
}

.platform-image {
  display: block;
  margin: auto;
  margin-bottom: 9.75rem;

  @media only screen and (max-width: $screen-sm-max) {
    display: none;
  }
}

.platform-image-mobile {
  display: none;

  @media only screen and (max-width: $screen-sm-max) {
    display: block;
    margin: 4rem 0 1.2rem;
  }
}

.help-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 20.75rem;
  margin: 3.75rem 0 4.75rem;

  @media only screen and (max-width: $screen-sm-max) {
    flex-wrap: nowrap;
    max-height: 88.5rem;
    margin: 0;
  }

  .help-item {
    position: relative;
    width: 50%;

    @media only screen and (max-width: $screen-sm-max) {
      margin: 0.5rem 0;
      height: 64px;
      width: 100%;
      display: flex;
      align-items: center;
    }

    .help-number {
      position: absolute;
      font-family: $font-name-ttnorms-medium;
      font-size: 5rem;
      color: transparent;
      -webkit-text-stroke: 2px #9393DF66;
      margin: 0;
      top: -12px;

      @media only screen and (max-width: $screen-sm-max) {
        top: -17px;
        font-size: 3.375rem;
      }
    }

    .help-name {
      width: fit-content;
      font-family: $font-name-ttnorms-medium;
      font-size: 1.25rem;
      color: #242240;
      margin: 2.125rem 0rem 2.125rem 4.375rem;

      @media only screen and (max-width: $screen-sm-max) {
        margin: 0.825rem 0 1.825rem 2.7rem;
        font-size: 1.125rem;
      }
    }
  }
}

.anchor {
  padding-top: 205px;
  margin-top: -145px;
}

// Webinar page

.webinar-intro-container {
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
  overflow: hidden;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;

  &.webinar {
    background-image: url("/assets/images/news/webinar_intro.svg");
  }

  &.telegram {
    background-image: url("/assets/images/telegram/intro.svg");
  }

  @media only screen and (max-width: $screen-sm-min) {
    margin-bottom: 4.5rem;

    &.webinar,
    &.telegram {
      background: none;
    }
  }
}

.webinar-title-wrapper {
  width: 75%;
  display: flex;
  flex-direction: column;
  font-family: $font-name-ttnorms-medium;
  margin: 1.75rem 0 1rem 2rem;

  @media only screen and (max-width: $screen-sm-min) {
    width: auto;
    margin: 0 1.25rem;
  }

  &.text-container {
    margin: 1.75rem 0 1rem 1.5rem;

    @media only screen and (max-width: $screen-sm-min) {
      margin: 0 1.25rem;
    }
  }

  .title {
    margin-bottom: 1.5rem;

    @media only screen and (max-width: $screen-sm-min) {
      font-size: 2rem;
      line-height: 2.1rem;
    }
  }

  .description {
    font-family: $font-name-ttnorms;
    font-size: 1.25rem;
    line-height: 2rem;
    color: #242240;
    margin-bottom: 1.5rem;
    max-width: 42rem;

    @media only screen and (max-width: $screen-sm-min) {
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }

  .additional-info {
    color: #9595A1;
    margin-bottom: 1rem;
    max-width: 42rem;
  }
}

.webinar-intro-buttons-wrapper {
  width: 50%;

  @media only screen and (max-width: $screen-sm-min) {
    width: auto;
  }
}

.webinar-intro-image-mobile {
  display: none;

  @media only screen and (max-width: $screen-sm-min) {
    display: block;
    margin: 1rem auto 2rem;
  }
}

.webinar-about-wrapper {
  @media only screen and (max-width: $screen-sm-min) {
    flex-direction: column;
  }
}

.webinar-about-image {
  width: 45.125rem;
  height: 51.75rem;
  margin-right: 2rem;

  @media only screen and (max-width: $screen-sm-min) {
    display: none;
  }
}

.webinar-about-image-mobile {
  display: none;

  @media only screen and (max-width: $screen-sm-min) {
    display: block;
    margin: 0;
  }
}

.webinar-question-item {
  cursor: default !important;
}

.webinar-about-list {
  display: flex;
  flex-direction: column;
  margin: 0;

  @media only screen and (max-width: $screen-sm-max) {
    margin: 0;
  }

  .about-item {
    position: relative;
    width: 100%;

    @media only screen and (max-width: $screen-sm-max) {
      margin: 1.5rem 0;
      height: 64px;
      display: flex;
      align-items: center;
    }

    .about-number {
      position: absolute;
      font-family: $font-name-ttnorms-medium;
      font-size: 6.25rem;
      line-height: 7.5rem;
      color: transparent;
      -webkit-text-stroke: 2px #9393DF66;
      top: 10px;

      @media only screen and (max-width: $screen-sm-max) {
        top: 0;
        font-size: 3.375rem;
        line-height: 3rem;
      }
    }

    .about-name,
    .about-name-short {
      font-family: $font-name-ttnorms-medium;
      font-size: 1.25rem;
      line-height: 2rem;
      color: #242240;
      margin: 2.4rem 2.75rem 2.4rem 5.75rem;

      @media only screen and (max-width: $screen-sm-max) {
        margin: 0.825rem 0 1.825rem 2.7rem;
        font-size: 1.125rem;
        line-height: 1.6rem;
      }
    }

    .about-name-short {
      margin: 3.5rem 2.75rem 3.5rem 5.75rem;

      @media only screen and (max-width: $screen-sm-max) {
        margin: 0.825rem 0 1.825rem 2.7rem;
      }
    }
  }
}

// Telegram-bot page

.telegram-intro-image-mobile {
  display: none;

  @media only screen and (max-width: $screen-sm-min) {
    display: block;
    margin: 1.625rem auto 1rem;
  }
}

.intro-text-container {
  min-width: 90rem;
  margin-bottom: 29.825rem;

  @media only screen and (max-width: $screen-sm-min) {
    min-width: auto;
    margin-bottom: 0;
  }
}

.telegram-section-container {
  max-width: 86.25rem;
  margin-bottom: 5.875rem;
  padding: 0 2.7rem;

  @media only screen and (max-width: $screen-sm-min) {
    padding: 0;
    margin-bottom: 2.5rem;
  }

  .telegram-description-wrapper {
    @media only screen and (max-width: $screen-sm-min) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  img {
    margin-bottom: 2rem;
  }
}

.telegram-title-wrapper {
  margin: 0 auto 1rem;
  max-width: 86.25rem;

  @media only screen and (max-width: $screen-sm-max) {
    margin: 0 1.25rem 1.25rem;
  }
}

.telegram-description {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  line-height: 2rem;

  @media only screen and (max-width: $screen-sm-max) {
    font-size: 1rem;
    margin: 0 1.25rem 2rem;
    line-height: 1.6rem;
  }

  &.last-item {
    @media only screen and (max-width: $screen-sm-max) {
      margin: 0 1.25rem 4.875rem;
    }
  }
}

// Water-meters,  pulse-meters, base-stations page

.devices-cooperation-container {
  .form-wrapper {
    padding-bottom: 0.625rem;

    @media only screen and (max-width: $screen-xs-max) {
      padding-bottom: 4.875rem;
    }
  }
}

// Heat-meters page

.heat-meters-cooperation-container {
  .form-wrapper {
    padding-bottom: 3.75rem;

    @media only screen and (max-width: $screen-xs-max) {
      padding-bottom: 4.875rem;
    }
  }
}

// Sensors for individuals

.individuals-image-wrapper {
  background-image: url("/assets/images/news/sensors_for_individuals.svg");
  background-repeat: no-repeat;
  background-position: center;

  @media only screen and (max-width: $screen-md) {
    background-image: none;
  }

  h1 {
    color: #393853;

    @media only screen and (max-width: $screen-md) {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
  }

  .webinar-intro-container {
    @media only screen and (max-width: $screen-md) {
      padding: 0;
      margin-bottom: 0;
    }

    .intro-text-container {
      margin-bottom: 24.5rem;

      @media only screen and (max-width: $screen-md) {
        margin-bottom: 0;
      }

      .webinar-title-wrapper {
        width: 50%;
        margin: 0 0 1rem 1.5rem;

        p {
          @media only screen and (max-width: $screen-md) {
            padding-left: 1.25rem;
            padding-right: 1.25rem;
          }
        }

        @media only screen and (max-width: $screen-md) {
          width: 100%;
          margin: 0;

          p:last-child {
            margin: 0;
          }
        }
      }
    }
  }
  
  .individuals-image-mobile {
    display: none;

    @media only screen and (max-width: $screen-md) {
      display: block;
      padding: 3.125rem 0 1.5rem 0;
    }
  }
}

.start-work-container {
  @media only screen and (max-width: $screen-md) {
    padding-top: 4.5rem;
  }

  h2 {
    font-size: 2.375rem;
    color: #393853;

    @media only screen and (max-width: $screen-md) {
      font-size: 1.5rem;
    }
  }

  .description-container {
    display: flex;
    flex-direction: column;

    .description {
      padding-top: 1.5rem;
      margin-bottom: 0;
      font-size: 1.25rem;
      font-family: $font-name-ttnorms;
      color: #242240;

      @media only screen and (max-width: $screen-md) {
        font-size: 1rem;
        padding-top: 1rem;
      }
    }

    .left-description {
      width: 50%;
      font-family: $font-name-ttnorms-medium;
      padding: 2rem 0 2.125rem 0;
      align-self: flex-end;

      @media only screen and (max-width: $screen-md) {
        width: 100%;
        align-self: flex-start;
        padding: 2.5rem 0 1.5rem 0;
      }
    }
  }
}

.benefits-individuals-container {
  padding-top: 7.875rem;

  @media only screen and (max-width: $screen-md) {
    padding: 2rem 0 6.25rem 0;
  }

  h2 {
    font-size: 2.375rem;
    color: #393853;

    @media only screen and (max-width: $screen-md) {
      font-size: 1.5rem;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
  }

  .description-individuals-container {
    display: flex;
    padding-top: 2.5rem;

    @media only screen and (max-width: $screen-md) {
      flex-direction: column;
    }

    .description-individuals-img {
      width: 50%;
      display: block;
      border-radius: 1rem;

      @media only screen and (max-width: $screen-md) {
        width: 100%;
        border-radius: 0;
      }
    }

    .description-text-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 50%;
      padding-left: 2rem;

      @media only screen and (max-width: $screen-md) {
        width: auto;
        padding: 2rem 1.25rem 0rem 1.25rem;
      }

      p:last-child {
        margin-bottom: 0;
      }

      .description {
        font-size: 1.25rem;
        color: #242240;
        font-family: $font-name-ttnorms;

        @media only screen and (max-width: $screen-md) {
          font-size: 1rem;
        }
      }

      .bold {
        font-family: $font-name-ttnorms-medium;
      }
    }
  }
}

.contact-us-individuals {
  border-top: 0;
  padding-top: 13.4rem;

  @media only screen and (max-width: $screen-md) {
    border-top: 1px solid #CCCCCC;
    padding-top: 6.25rem;
  }

  .form-title {
    margin-bottom: 3.68rem;

    @media only screen and (max-width: $screen-md) {
      margin-bottom: 2rem;
    }
  }
}

// Cookie banner

.cookie-banner-container {
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 50;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 7px #0000001A;


  h3 {
    font-size: 1.3125rem;
    margin-bottom: 0.5rem;

    @media only screen and (max-width: $screen-md) {
      font-size: 0.875rem;
    }
  }

  p {
    margin-bottom: 0;

    @media only screen and (max-width: $screen-md) {
      font-size: 0.6875rem;
      line-height: 1rem;
    }
  }

  .cookie-inner-container {
    display: flex;
    justify-content: space-between;
    padding: 0.81rem 1.875rem 0.68rem 1.875rem;

    @media only screen and (max-width: $screen-md) {
      flex-direction: column;
      padding: 0.81rem 1.25rem 1.125rem 1.25rem;
    }

    .main-text-container {
      display: flex;
      justify-content: space-between;

      .cookie-image {
        padding-right: 2rem;

        @media only screen and (max-width: $screen-md) {
          display: none;
        }
      }

      .main-text {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .banner-text {
          max-width: 53.125rem;

          @media only screen and (max-width: $screen-md) {
            max-width: none;
          }
        }

        .title-desktop {
          display: block;

          @media only screen and (max-width: $screen-md) {
            display: none;
          }
        }

        .title-mobile {
          display: none;

          @media only screen and (max-width: $screen-md) {
            display: block;
            margin-bottom: 6px;
          }
        }
      }
    }

    .button-container {
      display: flex;
      align-items: center;

      .button {
        box-sizing: border-box;
        height: 3.125rem;
        padding: 0;

        @media only screen and (max-width: $screen-md) {
          margin-top: 1rem;
          height: 2.56rem;
        }
      }
    }
  }
}