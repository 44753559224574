// -----------------------------------------------------------------------------
//  Breakpoints
// -----------------------------------------------------------------------------
//  01. Extra small screen / mobile
//  02. Small screen / tablet
//  03. Medium screen / tablet horizontal orientation
//  04. Desktop
//  05. Large desktop
//  06. Provide a maximum, so media queries don't overlap when required
// -----------------------------------------------------------------------------

//  01. Extra small screen / mobile
$screen-xs:                  425px;
$screen-xs-min:              $screen-xs;

//  02. Small screen / tablet
//$screen-sm:                  768px; return when tablet layout will be added
$screen-sm:                  1024px;
$screen-sm-min:              $screen-sm;

//  03. Medium screen / tablet horizontal orientation
$screen-md:                  1024px;
$screen-md-min:              $screen-md;

//  04. Desktop
$screen-lg:                  1180px;
$screen-lg-min:              $screen-lg;

//  05. Large desktop
$screen-xl:                  1440px;
$screen-xl-min:              $screen-xl;

//  06. Provide a maximum, so media queries don't overlap when required
$screen-xxs-max:             ($screen-xs-min - 1);
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);
$screen-lg-max:              ($screen-xl-min - 1);
