// -----------------------------------------------------------------------------
//  Promo -> Sections
// -----------------------------------------------------------------------------
//  01. Common
//  02. Intro section
//  03. Portfolio section
//  04. Equipment section
//  05. Software section
//  06. Why us section
// -----------------------------------------------------------------------------

//  01. Common
.section-title {
  font-family: $font-ttnorms-bold;
  font-size: 2.25rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: $c-grey-500;
  line-height: 4.125rem;
  letter-spacing: 0;
  margin: 0 0 0.813rem;
  padding: 0;

  @media only screen and (max-width: $screen-xs-max) {
    font-size: 1.75rem;
    margin-bottom: 0.625rem;
    line-height: 2.5rem;
  }
}

.section-subtitle {
  font-family: $font-ttnorms;
  font-variant-ligatures: none;
  font-size: 1.125rem;
  text-align: center;
  color: $c-grey-300;
  line-height: 1.688rem;
  letter-spacing: 0;
  margin: 0 11rem 5rem 11rem;
  padding: 0;
  opacity: 0.5;

  @media only screen and (max-width: $screen-md-max) {
    margin-bottom: 4.5rem;
    margin-right: 3rem;
    margin-left: 3rem;
  }

  @media only screen and (max-width: $screen-xs-max) {
    margin-bottom: 2.813rem;
    margin-right: 0;
    margin-left: 0;
  }
}

.tabs-outer-wrapper {
  @media only screen and (max-width: $screen-xs-max) {
    margin: 0 1.25rem 1.25rem;
  }

  .tabs-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .tabs-container {
    max-width: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }

  .tabs {
    display: flex;
    margin: 0;
    max-width: 100%;
  }

  .left-arrow,
  .right-arrow {
    position: absolute;
    top: 0.313rem;
    display: none;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 4rem;
    flex: 0 0 2rem;
    z-index: 2;

    @media only screen and (max-width: $screen-sm-max) {
      display: flex;
    }
  }

  .left-arrow {
    left: 0;
    background: linear-gradient(90deg, rgba(253,253,253,1) 0%, rgba(255,255,255,0) 80%, rgba(253,253,253,0) 100%);
  }

  .right-arrow {
    right: 0;
    background: linear-gradient(90deg, rgba(253,253,253,0) 0%, rgba(255,255,255,0) 20%, rgba(253,253,253,1) 100%);
  }
}

//  02. Intro section
.intro-container {
  position: relative;
  margin: 0rem auto 15.875rem;

  @media only screen and (max-width: $screen-sm-max) {
    margin-bottom: 14.375rem;
  }

  @media only screen and (max-width: $screen-xs-max) {
    padding: 0;
    margin-bottom: 0;
  }

  .intro-image-wrapper {
    display: flex;
    justify-content: center;
    z-index: 50;
    overflow-x: hidden;
  }

  .intro-image {
    position: absolute;
    top: -9.313rem;
    right: 0rem;
    width: 46.125rem;
    height: 54rem;

    &.firefox {
      @media only screen and (min-width: $screen-sm) {
        height: 50rem;
      }
      @media only screen and (min-width: $screen-xl) {
        height: 54rem;
      }
      @media only screen and (min-width: $screen-xl) {
        right: 0rem;
      }
      @media only screen and (max-width: 1280px) {
        right: -2rem;
        transform: scale(0.9);
      }
      @media only screen and (max-width: $screen-lg) {
        right: -12rem;
      }
      @media only screen and (min-width: 1280px) {
        right: 0rem;
      }
    }

    &.safari {
      @media only screen and (min-width: $screen-md) {
        height: 50rem;
      }

      @media only screen and (min-width: $screen-xl) {
        height: 54rem;
      }
    }

    @media only screen and (max-width: $screen-md-max) {
      right: -24.375rem;
    }

    &.intro-image-sm {
      display: none;

      @media only screen and (max-width: $screen-sm-max) {
        display: block;
        position: relative;
        right: 0;
        top: -1.25rem;
        height: auto;
        width: 100%;
      }

      @media only screen and (max-width: $screen-xs-max) {
        height: 26.875rem;
        width: auto;
        position: static;
        margin: 0 auto;
        margin-top: -1.25rem;
        max-width: none;
      }
    }

    &.intro-image-lg {
      @media only screen and (max-width: $screen-sm-max) {
        display: none;
      }
    }
  }
}

.left-section {
  align-items: center;
  display: flex;
  min-height: 37.75rem;
  width: 85%;

  @media only screen and (max-width: $screen-sm-max) {
    min-height: auto;
    width: 100%;
    margin-bottom: 7.5rem;
    justify-content: center;
  }

  .content {
    padding: 0 2rem 0 0;

    @media only screen and (max-width: $screen-sm-max) {
      text-align: center;
      width: 100%;
    }

    @media only screen and (max-width: $screen-xs-max) {
      padding: 0;
    }
  }

  .header-title {
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: $screen-sm-max) {
      padding: 0 1.25rem;
    }
  }

  .counters-container {
    display: flex;
    margin: 0 0 3.5rem 0;

    @media only screen and (max-width: $screen-sm-max) {
      display: none;
    }

    .counter-wrapper {
      display: flex;
      margin: auto;
      margin-left: 0;
      font-family: $font-ttnorms;
      color: $c-deep-purple-500;

      .count-wrapper {
        margin-top: -0.5rem;
        padding-left: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
      
        .count-number {
          font-size: 1.5rem;
          margin: 0;
        }

        .count-name {
          font-size: 1rem;
          margin: 0;
        }
      }
    }
  }

  .title {
    margin-bottom: 2rem;

    @media only screen and (max-width: $screen-sm-max) {
      text-align: left;
    }
  }

  .subtitle {
    font-family: $font-ttnorms;
    font-size: 1.25rem;
    color: #242240;
    line-height: 2.25rem;
    font-variant-ligatures: none;
    margin-bottom: 3rem;

    @media only screen and (max-width: $screen-sm-max) {
      margin-bottom: 0;
      margin-left: auto;
      margin-right: auto;
      max-width: 36.563rem;
    }

    @media only screen and (max-width: $screen-xs-max) {
      display: none;
    }

    &.safari {
      @media only screen and (min-width: $screen-lg) {
        width: 99%;
      }

      @media only screen and (min-width: 1250px) {
        width: 100%;
      }
    }
  }

  .intro-buttons-wrapper {
    @media only screen and (max-width: $screen-sm-max) {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      top: -1.375rem;
      margin: 0 1.25rem;
    }

    .intro-button {
      margin-right: 3rem;

      @media only screen and (max-width: $screen-sm-max) {
        margin-right: 0;
        margin-bottom: 1.188rem;
        margin-top: 3.188rem;
      }
    }

    .button {
      @media only screen and (max-width: $screen-sm-max) {
        width: 100%;
      }
    }
  }
}

.right-section {
  width: 50%;

  @media only screen and (max-width: $screen-sm-max) {
    width: 100%;
  }
}

//  03. Portfolio section
.portfolio-title-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 3rem;

  @media only screen and (max-width: $screen-sm-max) {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 1.25rem;
  }
}

.portfolio-container {
  position: relative;
  margin-bottom: 26.35rem;

  @media only screen and (max-width: $screen-xs-max) {
    padding: 10rem 0 1.25rem;
    margin-bottom: 3.5rem;
  }

  .swiper-wrapper.disabled {
    transform: translate3d(0px, 0, 0) !important;
  }

  .swiper-pagination.disabled {
    display: none;
  }
}

.portfolio-title-mobile {
  display: none;

  @media only screen and (max-width: $screen-sm-max) {
    display: block;
    margin: 0 1.25rem 1.875rem;
  }
}

.filters-container {
  position: relative; // position: relative prevents IE scroll bug (element with position: relative inside container with overflow: auto appear as "fixed")

  @media only screen and (max-width: $screen-xs-max) {
    padding: 0;
  }

  .tabs-menu-mobile {
    display: none;

    @media only screen and (max-width: $screen-sm-max) {
      display: block;
    }
  }

  .tag-wrapper {
    position: relative;
    top: 0;
    margin: 0 1.125rem;
    float: left;
    list-style: none;
    white-space: nowrap;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    .link {
      font-family: $font-sofia-pro-semi-bold;
      color: $c-grey-450;
      opacity: 0.5;
      float: left;
      cursor: pointer;

      &.active,
      &:hover,
      &:focus,
      &:active {
        opacity: 1;
        border-color: inherit;
      }

      &:hover:not(.active),
      &:focus:not(.active),
      &:active:not(.active) {
        border-color: transparent;
      }
    }
  }
}

.portfolio-cards {
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: $screen-sm-max) {
    flex-direction: column;
  }

  .card,
  .portfolio-card {
    display: none;
    position: relative;
    width: 40.75rem;

    @media only screen and (max-width: $screen-sm-max) {
      width: 100%;
    }

    img {
      width: 29.25rem;
      height: 19.5rem;
      border-radius: 10px;
      z-index: -1;

      @media only screen and (max-width: $screen-sm-max) {
        width: 100%;
        height: auto;
        border-radius: 0;
        margin-bottom: 16rem;
      }
    }

    .info {
      margin-bottom: 1.125rem;
      z-index: 1;
      background-color: white;
      border-radius: 1rem;
      position: absolute;
      top: 170px;
      left: 266px;
      padding: 2rem 0 2rem 2rem;

      @media only screen and (max-width: $screen-sm-max) {
        border-radius: 1rem 0 0 0;
        left: 20px;
        padding: 2rem;
      }
    }

    .name {
      margin-bottom: 1rem;
    }

    .description {
      margin-bottom: 2.25rem;
    }
  }
}

//  04. Equipment section
.equipment-container {
  position: relative;
  margin-bottom: 15.625rem;

  @media only screen and (max-width: $screen-xs-max) {
    margin-bottom: 5rem;
  }
}

.equipment-section-title-wrapper {
  margin-bottom: 3.125rem;

  @media only screen and (max-width: $screen-sm-max) {
    margin-bottom: 2rem;
  }
}

.equipment-cards-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.75rem;

  @media only screen and (max-width: $screen-sm-max) {
    flex-direction: column-reverse;
    margin-bottom: 0;
  }

  &.second-line-container {
    @media only screen and (max-width: $screen-sm-max) {
      flex-direction: column;
    }
  }

  .equipment-link-card {
    width: 20.75rem;
    height: 28.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    border: 1px solid #CCCCCC;
    border-radius: 16px;
    margin-right: 2.75rem;
    text-decoration: none;
    cursor: pointer;

    @media only screen and (max-width: $screen-sm-max) {
      width: auto;
      height: 14.125rem;
      margin: 0 0 2rem 0;
    }

    img {
      width: 57%;
      padding: 6.625rem 2rem;

      @media only screen and (max-width: $screen-sm-max) {
        width: 38%;
        max-width: 9.3rem;
        padding: 1.25rem;
      }
    }

    h3 {
      margin-bottom: 2rem;

      @media only screen and (max-width: $screen-sm-max) {
        margin-bottom: 1rem;
      }
    }

    &:hover {
      border: 1px solid #f37ea1;
    }

    &.second-line {
      margin-left: 2.75rem;
      margin-right: 0;

      @media only screen and (max-width: $screen-sm-max) {
        margin: 0 0 2rem 0;
      }
    }
  }

  .equipment-description-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FAFAFA 0% 0% no-repeat padding-box;
    border: 1px solid #EFEFEF;
    border-radius: 1rem;
    width: 40%;
    padding: 1.1rem 4.75rem;

    @media only screen and (max-width: $screen-sm-max) {
      width: auto;
      min-height: 20.625rem;
      padding: 2.625rem 0.75rem;
      margin: 0 0 2rem 0;
    }

    .title {
      text-align: center;
    }

    .description {
      text-align: center;
    }

    .button-container {
      margin-top: 2rem;

      @media only screen and (max-width: $screen-sm-max) {
        padding: 0 1.25rem;
        width: 85%;
      }
    }
  }
}

//  05. Software section

.software-section-title-wrapper {
  margin-bottom: 0;

  @media only screen and (max-width: $screen-sm-max) {
    margin: 0 1.25rem;
  }
}

.software-container {
  position: relative;
  margin-bottom: 10.5rem;

  .software-menu-container,
  .software-menu-container-mobile {
    position: -webkit-sticky;
    position: sticky;
    top: 5.9rem;
    display: flex;
    min-height: 2rem;
    background: white;
    padding: 2.5rem 0;
    z-index: 25;

    @media only screen and (max-width: $screen-sm-max) {
      display: none;
    }

    .software-menu-item {
      font-family: $font-sofia-pro-semi-bold;
      font-weight: 700;
      font-size: 1.125rem;
      text-decoration: none;
      text-transform: uppercase;
      color: $c-grey-450;
      opacity: 0.5;
      cursor: pointer;
      margin-right: 2.25rem;

      &:hover {
        opacity: 1;
        text-decoration: none;
      }

      &.active {
        opacity: 1;
        border-bottom: 2px solid #242240;
      }
    }
  }

  .software-menu-container-mobile {
    display: none;
    top: 4.2rem;

    @media only screen and (max-width: $screen-sm-max) {
      display: flex;
      width: 100%;
    }

    .software-menu-item {
      display: none;
      margin: 0 1.25rem;

      &.active {
        display: block;
        opacity: 1;
        border-bottom: 2px solid #242240;
      }
    }
  }

  @media only screen and (max-width: $screen-sm-max) {
    padding: 10rem 0 1.25rem;
    margin-bottom: 0;
  }
}

.software-section-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 9.375rem;

  @media only screen and (max-width: $screen-sm-max) {
    flex-direction: column;
    margin-bottom: 6.5rem;
  }

  img {
    width: 50%;
    margin-right: 2.5rem;

    &.software-img-first {
      margin-top: 2.5rem;
    }

    @media only screen and (max-width: $screen-sm-max) {
      width: 100%;
      margin: 0;
    }
  }

  .description {
    margin-bottom: 2.25rem;

    @media only screen and (max-width: $screen-sm-max) {
      margin-bottom: 2rem;
    }
  }

  &.middle-card {
    @media only screen and (max-width: $screen-sm-max) {
      flex-direction: column-reverse;
    }
  }
}

.software-description-card {
  @media only screen and (max-width: $screen-sm-max) {
    padding: 2rem 1.25rem;
  }
}

//  06. Blog section
.blog-container {
  margin-bottom: 12.5rem;

  @media only screen and (max-width: $screen-sm-max) {
    padding: 0rem 0 1.25rem;
    margin-bottom: 4.188rem;
  }
}

.blog-title-wrapper {
  margin-bottom: 2.4rem;

  @media only screen and (max-width: $screen-sm-max) {
    padding: 0 1.25rem;
    margin-bottom: 2rem;
  }
}

.blog-items-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5.125rem;

  @media only screen and (max-width: $screen-sm-max) {
    flex-direction: column;
    margin-bottom: 0;
  }
}

.blog-item-main {
  border: 1px solid #CCCCCC;
  border-radius: 16px;
  background-image: url("/assets/images/news/sensors_for_individuals_without_line.svg");
  background-repeat: no-repeat;
  background-position: bottom 0 right 10%;
  text-decoration: none;
  padding: 2rem;
  margin-right: 2rem;
  max-width: 37.625rem;
  height: 24.5rem;

  &.firefox {
    @media only screen and (max-width: 1355px) {
      height: inherit;
    }
  }

  &.safari {
    width: fit-content;
  }

  &:hover {
    text-decoration: none;
    border: 1px solid #f37ea1;
  }

  @media only screen and (max-width: $screen-sm-max) {
    display: none;
  }
}

.blog-item-small-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .blog-item-main-mobile,
  .blog-item-small {
    display: flex;
    text-decoration: none;

    &.firefox {
      @media only screen and (max-width: 1280px) {
        flex-direction: column;
      }
    }

    @media only screen and (max-width: $screen-sm-max) {
      flex-direction: column;
      margin-bottom: 3.75rem;
    }

    img {
      border: 1px solid #CCCCCC;
      border-radius: 16px;
      width: 20.375rem;
      height: 13.25rem;
      margin-right: 1.5rem;

      @media only screen and (max-width: $screen-sm-max) {
        display: none;
      }

      &.mobile {
        display: none;

        @media only screen and (max-width: $screen-sm-max) {
          display: block;
          border: none !important;
          border-radius: 0;
          margin: 0 0 1rem 0;
          width: 100%;
          height: auto;
        }
      }
    }

    .text-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      @media only screen and (max-width: $screen-sm-max) {
        padding: 0 1.25rem;
      }
    }

    &:hover {
      text-decoration: none;

      img {
        border: 1px solid #f37ea1;
      }
    }
  }

  .blog-item-second {
    @media only screen and (max-width: 1360px) {
      padding-bottom: 10px;
    }
  }

  .blog-item-main-mobile {
    display: none;

    @media only screen and (max-width: $screen-sm-max) {
      display: flex;
    }
  }
}

.date {
  font-size: 1rem;
  color: #9595A1;
  margin-bottom: 0.375rem;

  @media only screen and (max-width: $screen-sm-max) {
    font-size: 0.875rem;
  }
}

.blog-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
