// -----------------------------------------------------------------------------
// Footer
// -----------------------------------------------------------------------------

.footer {
  background-color: $c-deep-purple-400;
  padding: 8.125rem 0;

  @media only screen and (max-width: $screen-xs-max) {
    padding: 5rem 0;
  }
}

.footer-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .logo {
    width: 10.625rem;
    height: 2.375rem;
    background-size: contain;
    margin-bottom: 3.125rem;
    position: relative;

    svg {
      width: 100%;
      position: absolute;
      top: -0.063em;
      left: 0;
    }
  }

  .links-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    margin: 0 0 3.5rem;

    @media only screen and (max-width: $screen-xs-max) {
      flex-direction: column;
      text-align: center;
    }

    .footer-link-wrapper {
      padding: 0 1.125rem;
      margin-bottom: 1.5rem;
    }

    .link {
      font-family: $font-sofia-pro-semi-bold;
      color: $c-grey-470;
      opacity: 0.7;
    }
  }

  .social-links-container {
    display: flex;
    align-items: center;
    margin: 0 0 2.375rem;
    list-style: none;

    .social-link-wrapper {
      padding: 0 1.375rem;
    }

    .social-link {
      outline: 0;

      &:hover,
      &:active {
        svg {
          .fraction {
            fill: $c-grey-470;
          }
        }
      }

      svg {
        .fraction {
          fill: $c-deep-purple-450;
        }

        .background {
          fill: $c-deep-purple-400;
        }
      }
    }
  }

  .copyright-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $c-deep-purple-450;
    letter-spacing: 0.044rem;
    font-size: 0.875rem;

    @media only screen and (max-width: $screen-xs-max) {
      flex-direction: column;
      text-align: center;
    }

    .vertical-divider {
      margin: 0 0.438rem;
    }

    .copyright-contacts {
      padding-bottom: 1.5rem;
    }

    .lang-buttons-container {
      display: none;

      @media only screen and (max-width: $screen-xs-max) {
        display: block;
      }
    }

    .copyright-link {
      color: $c-deep-purple-450;
      border-bottom: 0.125rem solid transparent;
      outline: 0;
      text-decoration: none;

      @media only screen and (max-width: $screen-xs-max) {
        margin-bottom: 0.313rem;
      }

      &:hover,
      &:focus,
      &:active {
        border-bottom-color: inherit;
      }

      &.lang {
        margin-right: 0.8rem;
      }

      &.active {
        color: #7B77D1;
      }
    }
  }
}
