.connection-legal-page {
  body {
    zoom: normal;
  }

  &.chrome {
    body {
      zoom: 85%;

      @media only screen and (min-width: 1550px) {
        zoom: 100%;
      }
    }
  }

  h1 {
    font-family: $font-name-ttnorms-medium;
    font-size: 3.875rem;
    letter-spacing: 0px;
    line-height: 80px;
    color: $c-grey-450;

    @media only screen and (max-width: $screen-md-max) {
      font-size: 1.8rem;
      line-height: 2.125rem;
    }

    .dark {
      color: $c-grey-500;
    }
  }

  h2 {
    font-size: 2.375rem;
    color: $c-grey-500;

    @media only screen and (max-width: $screen-sm) {
      font-size: 1.5rem;
    }
  }

  p {
    font-family: $font-name-ttnorms;
    color: $c-grey-450;
  }

  .header,
  .scroll-menu {
    .container {
      padding: 0;
    }
  }

  .container {
    box-sizing: border-box;
    width: 100%;
    max-width: 90rem;
    padding: 1.875rem 1.875rem 16rem 1.875rem;
    margin: 0 auto;

    @media only screen and (max-width: $screen-sm-min) {
      width: auto;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
  }


  #email-alert-first,
  #email-alert-second {
    display: none;
    height: 51px;
    color: red;
  }

  .viber {
    color: #2a7ae2;
  }

  .image-hidden {
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  .image-hidden-sm {
    @media only screen and (min-width: 768px) {
      display: none;
    }
  }

  .installation-cost {
    padding-top: 1rem;
    color: #F37EA1;
    margin-bottom: 2.5rem;

    @media only screen and (min-width: $screen-lg) {
      margin-bottom: 0;
    }
  }

  .landing-form {
    position: relative;
    width: 70%;

    @media only screen and (max-width: $screen-sm-min) {
      width: 100%;
    }

    @media only screen and (min-width: 430px){
      width: 50%;
    }

    @media only screen and (min-width: 1025px){
      width: 100%;
    }

    .input-email {
      display: block;
      box-sizing: border-box;
      outline: none;
      height: 64px;
      min-width: 100%;
      padding: 1.31rem 0 1.31rem 1.31rem;
      border: 1px solid #9A9A9A;
      border-radius: 16px;
      font-size: 1.125rem;
      font-family: $font-name-ttnorms;

      @media only screen and (max-width: $screen-md-max) {
        height: 47px;
        padding-left: 1rem;
        border-radius: 10px;
        font-size: 0.875rem;
      }

      &.safari {
        width: 100%;
      }
    }

    .input-button {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      outline: none;
      z-index: 2;
      min-width: 64px;
      max-height: 64px;
      margin-left: -64px;
      border: 1px solid #F37EA1;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
      white-space: nowrap;
      color: #ffffff;
      background-color: #F37EA1;

      @media only screen and (max-width: $screen-md-max) {
        height: 47px;
        min-width: 48px;
        margin-left: -48px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      &:hover {
        background-color: #F195AFFF;
        cursor: pointer;
      }

      img {
        vertical-align: baseline;

        @media only screen and (max-width: $screen-sm-min) {
          transform: scale(0.75);
        }
      }

      &.safari {
        height: 64px;
        width: 64px;

        @media only screen and (max-width: $screen-md-max) {
          min-width: 47px;
          height: 47px;
          width: 47px;
        }
      }
    }

    label {
      display: block;
      margin-top: 1rem;
    }

    .error {
      color: red;
      border-color: red;
    }
  }

  .image-wrapper {
    margin: 5rem 2rem 11rem 2rem;
    background-image: url("/assets/images/connection_legal_entities/all_system_icon.svg");
    background-repeat: no-repeat;
    background-position: 80% 0%;
    padding-bottom: 15rem;

    @media only screen and (max-width: $screen-md-max) {
      height: fit-content;
      background-image: none;
    }

    @media only screen and (max-width: $screen-sm) {
      margin: 4rem 0 0 0;
      padding: 0 1.25rem 0 1.25rem;
    }

    .container {

      @media only screen and (max-width: $screen-sm-min) {
        padding: 0;
      }

      @media only screen and (min-width: $screen-sm) {
        padding: 0;
      }

      @media only screen and (min-width: $screen-lg) {
        padding: 1.875rem 0;
        width: 86.25rem;
      }

      @media only screen and (min-width: 1441px) {
        max-width: 86.25rem;
        margin: 0 auto;
      }
    }

    .intro-landing-container {
      .landing-content-container {

        .landing-content {
          display: flex;
          flex-direction: column;

          .landing-image-sm {
            width: 100%;
            padding: 2.5rem 0 2.5rem 0;

            @media only screen and (min-width: 700px) {
              width: 90%;
            }

            @media only screen and (min-width: 800px) {
              width: 60%;
              padding: 0.5rem 0;
            }

            @media only screen and (min-width: 1025px) {
              width: 50%;
            }

            @media only screen and (min-width: $screen-lg) {
              display: none;
            }

            @media only screen and (width: $screen-sm) {
              display: block;
            }
          }

          .intro-subtitle {
            width: 40rem;
            padding: 3.125rem 0;
            font-family: $font-ttnorms;
            font-size: 1.25rem;
            color: $c-grey-450;

            @media only screen and (max-width: $screen-md-max) {
              padding: 1rem 0 0 0;
            }

            @media only screen and (max-width: 800px) {
              display: none;
            }

            @media only screen and (max-width: $screen-sm) {
              width: 100%;
            }
          }

          .landing-form {
            width: 30rem;

            @media only screen and (max-width: 500px) {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .benefits-landing-container {
    padding-bottom: 13rem;
    padding-top: 6.875rem;

    @media only screen and (max-width: $screen-sm-min) {
      padding-bottom: 3rem;
      padding-top: 1.5rem;
    }

    .benefits-landing-inner-container {
      display: flex;
      justify-content: space-between;

      @media only screen and (max-width: $screen-lg) {
        flex-direction: column;
      }

      .benefits-description-container {
        display: flex;
        flex-direction: column;

        .image-container {
          align-items: center;
          justify-content: center;
          display: flex;
          height: 5rem;
          width: 5rem;
          margin-bottom: 1.5rem;
          box-sizing: border-box;
          border-radius: 50%;

          @media only screen and (max-width: $screen-sm-min) {
            margin-left: auto;
            margin-right: auto;
          }
        }

        .image-greed-description {
          width: 19.5rem;

          @media only screen and (max-width: $screen-sm-min) {
            width: 100%;
          }
        }

        .greed-container {
          padding-right: 5rem;

          @media only screen and (max-width: $screen-sm-min) {
            padding-right: 0;
          }
        }

        .greed-container-sm {
          @media only screen and (max-width: $screen-sm-min) {
            padding-top: 3.25rem;
          }
        }

        .image-greed {
          @media only screen and (max-width: $screen-sm-min) {
            padding-top: 3.25rem;
          }
        }

        .greed-top-container {
          display: flex;
          flex-direction: row;
          padding: 0.8rem 0 6.25rem 5rem;

          @media only screen and (max-width: $screen-sm-min) {
            flex-direction: column;
            text-align: center;
            padding: 5rem 0 0 0;
          }

          @media only screen and (min-width: 1025px) {
            padding: 4rem 0 6.25rem 0;
          }

          @media only screen and (min-width: 1181px) {
            padding: 0.8rem 0 6.25rem 5rem;
          }

          .image-support {
            border: 1px solid #F37EA1;
            background-color: #F37EA1;
            box-shadow: 0px 0px 10px #F37EA180;
          }

          .image-price {
            border: 1px solid #6FCAC9;
            background-color: #6FCAC9;
            box-shadow: 0px 0px 10px #6FCAC980;
          }
        }

        .greed-bottom-container {
          display: flex;
          flex-direction: row;
          padding: 0 0 0 5rem;

          @media only screen and (max-width: $screen-sm-min) {
            flex-direction: column;
            text-align: center;
            padding: 0;
          }

          @media only screen and (min-width: 1025px) {
            padding: 0;
          }

          @media only screen and (min-width: 1181px) {
            padding: 0.8rem 0 0 5rem;
          }

          .image-tool {
            border: 1px solid #FF9655;
            background-color: #FF9655;
            box-shadow: 0px 0px 10px #FF965580;
          }

          .image-key {
            border: 1px solid #B888F8;
            background-color: #B888F8;
            box-shadow: 0px 0px 10px #B888F880;
          }
        }
      }

      .benefits-button-container {
        padding-top: 3.5rem;

        @media only screen and (max-width: $screen-sm-min){
          padding-top: 1rem;
        }

        .learn-more-button {
          cursor: pointer;

          p {
            font-family: $font-sofia-pro-semi-bold;
            color: $c-mauvelous;
          }
        }
      }

      .benefits-text-container{
        max-width: 34.6rem;

        .description-text{
          padding-top: 1rem;
        }
      }
    }
  }

  .dispatching-cost {
    background-color: #F6F6F6;
    background-image: url("/assets/images/connection_legal_entities/dispatching_icon.svg");
    background-repeat: no-repeat;
    background-position: 102.5% 114%;

    @media only screen and (max-width: $screen-sm-min) {
      background-size: 50%;
      background-position: 110% 106%;
    }

    .dispatching-cost-container {
      display: flex;
      padding: 6.25rem 1.875rem 5.3125rem 1.875rem;

      @media only screen and (max-width: $screen-sm-min) {
        flex-direction: column;
        padding: 6.25rem 1.25rem 4.75rem 1.25rem;
      }

      .left-dispatching-container {
        @media only screen and (max-width: $screen-sm-min) {
          padding-bottom: 2rem;
          h2 {
            font-size: 23px;
          }
        }
      }

      .right-dispatching-container {
        padding-left: 12.25rem;
        padding-top: 1rem;

        @media only screen and (max-width: $screen-sm-min) {
          padding: 0;
        }

        .text-wrapper {
          display: flex;

          @media only screen and (max-width: $screen-sm-min) {
            flex-direction: column;
            padding-bottom: 1rem;
          }

          .dispatching-text-title {
            width: 20.75rem;
            padding-right: 3rem;
          }

          .dispatching-text-description {
            color: #F37EA1;
          }

          p {
            margin-bottom: 2rem;
            font-family: $font-ttnorms-medium;
            font-size: 1.3125rem;

            @media only screen and (max-width: $screen-sm-min) {
              margin-bottom: 0.5rem;
              font-size: 1.125rem;
            }
          }
        }
      }
    }
  }

  .landing-connection-container {
    padding-top: 10rem;

    @media only screen and (max-width: $screen-sm-min){
      padding-bottom: 6.5rem;
      padding-top: 5.875rem;
    }


    .landing-connection-header {
      text-align: center;

      @media only screen and (max-width: $screen-sm-min){
        text-align: left;
      }

      p {
        padding-top: 1.375rem;
        font-size: 1.25rem;

        @media only screen and (max-width: $screen-sm-min){
          font-size: 1rem;
        }
      }
    }

    .connection-container {
      padding-top: 9.25rem;

      @media only screen and (max-width: $screen-sm-min){
        padding-top: 3.75rem;
      }

      a {
        color: #2a7ae2;
      }

      .top-connection-content {
        .top-image-container {
          @media only screen and (min-width: $screen-sm){
            width: 50%;
          }
        }
      }
    }

    .landing-connection-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media only screen and (max-width: $screen-sm-min){
        flex-direction: column;
      }

      .top-connection-content {
        display: flex;
        width: 50%;

        @media only screen and (max-width: 500px){
          width: 100%;
        }

        .first-image-container {
          position: relative;
          bottom: -11rem;
          left: 1.5rem;
          z-index: -1;
          width: fit-content;
          font-family: $font-ttnorms-medium;
          font-size: 17.5rem;
          color: transparent;
          -webkit-text-stroke: 2px #9393DF66;

          @media only screen and (max-width: $screen-lg){
            left: -6.5rem;
            bottom: -6rem;
            z-index: -1;
            font-size: 8.9rem;
          }

          @media only screen and (width: $screen-sm){
            left: -6.5rem;
            bottom: -3rem;
            font-size: 12rem;
          }
        }

        .second-image-container {
          position: relative;
          bottom: -11rem;
          left: 15rem;
          z-index: -1;
          font-family: $font-ttnorms-medium;
          font-size: 17.5rem;
          color: transparent;
          -webkit-text-stroke: 2px #9393DF66;

          @media only screen and (max-width: $screen-lg){
            left: 7.5rem;
            bottom: -6rem;
            z-index: -1;
            font-size: 8.9rem;
          }

          @media only screen and (width: $screen-sm){
            left: 8rem;
            bottom: -3rem;
            font-size: 12rem;
          }
        }

        .top-image-container {
          width: 50%;

          @media only screen and (max-width: $screen-sm){
            width: 100%;
          }

          img {
            max-width: none;

            @media only screen and (max-width: $screen-lg){
              height: 220px;
            }
          }
        }
      }

      .top-connection-content-sm {
        @media only screen and (max-width: $screen-xs){
          position: relative;
          left: -8rem;
        }

        @media only screen and (min-width: 426px){
          justify-content: flex-end;
          width: 100%;
        }

        .top-image-container-sm {
          width: 100%;

          @media only screen and (max-width: $screen-sm){
            width: fit-content;
          }
        }
      }

      .top-connection-description {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 7rem;

        @media only screen and (max-width: $screen-sm-min){
          width: 100%;
          text-align: center;
          padding-left: 0;
          padding-top: 4.5rem;
        }

        @media only screen and (width: $screen-sm){
          padding-top: 7rem;
        }

        .text-description {
          width: 100%
        }

        p {
          .viber {
            color: #2a7ae2;
          }
        }
      }

      .top-connection-description-sm {
        @media only screen and (max-width: $screen-sm-min){
          padding-top: 0;
        }
      }
    }

    .second-connection-container {
      padding-top: 10rem;

      @media only screen and (max-width: $screen-sm-min){
        display: flex;
        flex-direction: column-reverse;
        padding-top: 3.75rem;
      }

      .top-connection-content {
        width: 100%;

        @media only screen and (min-width: $screen-lg){
          width: 50%;
        }
      }

      .top-connection-description {
        width: 100%;
      }
    }

    .reverse {

      @media only screen and (max-width: $screen-sm-min){
        flex-direction: column;
      }

      .top-connection-content {
        width: 50%;

        @media only screen and (max-width: 375px){
          position: relative;
          left: -2rem;
          width: 100%;
        }

        @media only screen and (min-width: 375px){
          position: relative;
          left: -1.4rem;
          width: 100%;
        }

        @media only screen and (min-width: $screen-xs){
          position: relative;
          left: 0;
          width: 100%;
        }

        @media only screen and (min-width: 500px){
          position: static;
          width: 100%;
        }

        @media only screen and (min-width: 500px){
          width: 50%;
        }

        .first-image-container {
          @media only screen and (max-width: $screen-lg){
            left: -6.5rem;
            bottom: -6rem;
            z-index: -1;
            font-size: 8.9rem;
          }

          @media only screen and (width: $screen-sm){
            left: -6.5rem;
            bottom: -3rem;
            font-size: 12rem;
          }
        }
      }

      .top-connection-description {
        @media only screen and (min-width: $screen-sm){
          width: 50%;
        }
      }
    }
  }

  .landing-consultation {
    background-color: #F6F6F6;

    .landing-consultation-container {
      display: flex;
      justify-content: space-between;
      padding: 6.25rem 1.875rem 7.2rem 1.875rem;
      margin-bottom: 10rem;

      @media only screen and (max-width: $screen-sm-min){
        flex-direction: column;
        margin-bottom: 0;
        padding: 6.25rem 1.25rem 6.25rem 1.25rem;
      }

      .right-consultation-container {
        width: 37rem;

        @media only screen and (max-width: $screen-sm-min){
          width: 100%;
        }

        .consultation-description-container {
          padding-top: 0.5rem;

          .landing-form {
            width: 100%;

            @media only screen and (min-width: 600px){
              width: 70%;
            }

            @media only screen and (min-width: 1025px){
              width: 100%;
            }
          }

          p {
            padding-bottom: 2rem;

            a {
              color: #2a7ae2;
              font-family: $font-name-ttnorms-medium;
            }
          }

          .form-container-alert {
            position: absolute;
            margin-top: 1rem;
          }
        }
      }
    }
  }

  .landing-counters-container {
    padding-bottom: 10rem;

    @media only screen and (max-width: $screen-sm-min){
      padding-top: 6.3rem;
      padding-bottom: 6.25rem;
    }

    .counters-header {
      padding-top: 5.5rem;
      text-align: center;

      @media only screen and (max-width: $screen-sm-min){
        padding-top: 0;
        text-align: left;
      }

      p {
        font-size: 1.25rem;
        padding-top: 1.375rem;

        @media only screen and (max-width: $screen-sm-min){
          font-size: 1rem;
        }
      }
    }

    .counters-content-container {
      display: flex;
      flex-direction: row;
      padding-top: 11.5rem;

      @media only screen and (max-width: $screen-md){
        flex-direction: column;
        padding-top: 6.9rem;
      }

      @media only screen and (min-width: 768px){
        flex-direction: column;
        padding-top: 9rem;
      }

      @media only screen and (min-width: $screen-lg){
        flex-direction: row;
        padding-top: 11.5rem;
      }

      .left-cell {
        margin-right: 2.25rem;

        @media only screen and (max-width: $screen-sm-min){
          margin-right: 0;
        }
      }

      .right-cell {
        margin-left: 2.25rem;

        @media only screen and (max-width: $screen-lg){
          margin-top: 10rem;
          margin-left: 0;
        }

        @media only screen and (max-width: $screen-xs){
          margin-left: 0;
          margin-top: 7rem;
        }
      }

      .counters-cell {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 45%;
        padding: 9.875rem 3.5rem 2.5rem 3.5rem;
        border: 1px solid #CCCCCC;
        border-radius: 16px;
        text-align: center;

        @media only screen and (max-width: $screen-lg){
          width: auto;
          padding: 5.6rem 1.2rem 2.5rem 1.2rem;
        }

        @media only screen and (min-width: 768px){
          width: auto;
          padding: 10rem 1rem 1rem 1rem;
        }

        @media only screen and (min-width: $screen-lg){
          width: 45%;
          padding: 9.875rem 3.5rem 2.5rem 3.5rem;
        }

        .counters-image {
          position: absolute;
          height: 0;
          left: 50%;
          overflow: visible;
          transform: translateX(-50%);

          .counters-image-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            p {
              padding: 2.8rem;
              font-size: 2.875rem;
              color: #9393DF;
            }

            img:first-child {
              @media only screen and (max-width: $screen-sm-min){
                max-width: 85%;
              }
            }

            img:last-child {
              @media only screen and (max-width: $screen-sm-min){
                max-width: 30%;
              }
            }
          }
        }

        .individual-sensor-et {
          top: -5.9rem;
          width: 25rem;

          @media only screen and (max-width: 767px){
            top: -3.5rem;
            width: 16rem;
          }
        }

        .individual-sensor {
          top: -7.2rem;
          width: 24.1rem;

          @media only screen and (max-width: 767px){
            top: -5rem;
            width: 15rem;
          }
        }

        .vane-meters {
          top: -9.8rem;
          width: 34.3rem;

          @media only screen and (max-width: 767px){
            top: -5.5rem;
            width: 19.8rem;
          }

          @media only screen and (max-width: 375px){
            width: 18.8rem;
          }
        }

        .vane-impulse {
          top: -10rem;
          width: 31.8rem;

          @media only screen and (max-width: 767px){
            top: -6.5rem;
            width: 20.5rem;
          }

          @media only screen and (max-width: 375px){
            width: 18.5rem;
          }
        }

        .turbine-sensor {
          top: -8rem;
          width: 28.8rem;

          @media only screen and (max-width: 767px){
            top: -4.5rem;
            width: 16.9rem;
          }
        }

        .combined-sensor {
          top: -6.4rem;
          width: 27.6rem;

          @media only screen and (max-width: 767px){
            top: -4rem;
            width: 17.4rem;
          }
        }
      }
    }

    .counters-content-container-sm {

      @media only screen and (min-width: 768px){
        display: flex;
      }
    }
  }

  .landing-telegram-container {
    padding-top: 6rem;
    padding-bottom: 10rem;

    @media only screen and (max-width: $screen-sm-min){
      padding-top: 5rem;
      padding-bottom: 3rem;
    }

    h2 {
      text-align: center;
      margin-bottom: 3rem;

      @media only screen and (max-width: $screen-sm-min){
        text-align: left;
      }
    }

    .telegram-content-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media only screen and (max-width: 767px){
        flex-direction: column-reverse;
      }

      .telegram-content-list {
        display: flex;
        flex-direction: column;

        .telegram-content-row {
          display: flex;
          flex-direction: row;
          padding-bottom: 2rem;

          @media only screen and (max-width: $screen-sm-min){
            padding-bottom: 0;
          }

          img {
            padding-right: 1.5rem;

            @media only screen and (max-width: $screen-sm-min){
              padding-right: 0;
              max-width: none;
            }
          }

          p {
            font-size: 1.25rem;

            @media only screen and (max-width: $screen-sm-min){
              margin-bottom: 1.5rem;
              font-size: 1rem;
            }
          }

          div {
            @media only screen and (max-width: $screen-sm-min){
              padding-right: 1.5rem;
            }
          }
        }

        .top-row {
          padding-top: 3.5rem;
        }
      }

      .telegram-image-container {
        width: 608px;
        height: 620px;

        @media only screen and (max-width: $screen-md){
          width: 335px;
          height: 342px;
        }

        @media only screen and (max-width: 768px){
          align-self: center;
        }
      }
    }
  }

  .landing-questions-container {
    padding: 1.875rem 1.875rem 11.5rem 1.875rem;

    @media only screen and (max-width: $screen-sm-min){
      padding: 1.875rem 0 4rem 0;

      .services-name-wrapper {
        padding: 1.6rem 1.25rem;
      }

      .main-text {
        padding: 0 1.25rem;
      }
    }

    .services-list {
      padding: 0;
      margin: 0;

      li {
        list-style-type: none;
      }

      li:last-child {
        border-bottom: 1px solid #CCCCCC;
      }

      .services-item {
        .services-name-wrapper {
          .close-button {
            padding-left: 2rem;
            margin-left: 1.5rem;
            max-height: 50px;
            transition: max-height 0.5s ease-in;
          }

          .services-text {
            line-height: 2rem;

            @media only screen and (max-width: $screen-sm-min){
              font-size: 1.125rem;
            }
          }
        }

        .services-description {
          transition: max-height 0.25s ease-out, margin-bottom 0.25s ease;
        }
      }

      .services-item-open {
        .services-name-wrapper {
          .close-button {
            max-height: 2px;
            cursor: pointer;
            transition: max-height 0.5s ease-out;
          }
        }

        .services-description {
          max-height: 500px;
          margin-bottom: 1.5rem;
          transition: max-height 0.5s ease-in, margin-bottom 0.5s ease;
        }
      }
    }
  }

  .landing-contacts-container {
    position: relative;
    z-index : 1;
    background: linear-gradient(to right, #F6F6F6 50%, #FFFFFF 50%);

    @media only screen and (max-width: $screen-sm-min){
      background: #FFFFFF;
    }

    h2 {
      padding-top: 6.25rem;
      padding-bottom: 1rem;
    }

    .container {
      display: flex;
      flex-direction: row;
      padding: 0 1.875rem;

      @media only screen and (max-width: $screen-sm-min){
        flex-direction: column-reverse;
        padding: 0 1.25rem 0 1.25rem;
        background: linear-gradient(to top, #F6F6F6 52%, #FFFFFF 50%);
      }

      @media only screen and (max-width: 500px){
        background: linear-gradient(to top, #F6F6F6 56%, #FFFFFF 50%);
      }

      .landing-left-container {
        width: 50%;
        margin-right: 1rem;

        @media only screen and (max-width: $screen-sm-min){
          width: 100%;
        }

        .landing-header {
          p {
            width: 34.6rem;
            margin-bottom: 1.5rem;

            @media only screen and (max-width: 1250px){
              width: 100%;
              margin-bottom: 1rem;
            }
          }
        }

        .landing-contact {
          display: flex;
          flex-direction: row;
          padding-top: 2.4rem;

          @media only screen and (max-width: $screen-sm-min){
            flex-direction: column;
            padding-top: 1rem;
          }

          .landing-contact-left,
          .landing-contact-right {
            display: flex;
            flex-direction: column;
            padding-right: 6rem;

            @media only screen and (max-width: $screen-sm-min){
              padding-right: 0;
            }

            a {
              color: #2a7ae2;
            }

            p {
              .viber {
                color: #2a7ae2;
              }
            }
          }

          .landing-contact-left {
            &.safari {
              padding-right: 5rem;

              @media only screen and (max-width: $screen-sm-min){
                padding-right: 0;
              }
            }
          }

          .landing-contact-right {
            @media only screen and (max-width: $screen-sm-min){
              padding-top: 1rem;
            }
          }
        }

        .landing-working-contact {
          padding-right: 6rem;
          padding-top: 3rem;
          margin-bottom: 6.25rem;

          p {
            margin-bottom: 0;
          }

          @media only screen and (max-width: $screen-sm) {
            padding-top: 1rem;
          }
        }
      }

      .landing-right-container {
        width: 50%;
        background-color: #FFFFFF;

        @media only screen and (max-width: $screen-sm-min){
          width: 100%;
        }

        .landing-right-inner-container {
          padding-left: 4rem;

          @media only screen and (max-width: $screen-sm-max) {
            padding-left: 0;
          }

          @media only screen and (width: $screen-sm){
            padding: 0;
          }

          .send-button-container {
            display: flex;
            justify-content: center;
            padding-bottom: 2.3rem;
            padding-top: 2rem;

            @media only screen and (max-width: $screen-sm-max){
              padding-top: 1.5rem;
              height: 3.25rem;
            }
            .button {
              p {
                font-family: $font-sofia-pro-semi-bold;
                color: #fff;
              }
            }
          }

          .form-alert {
            display: none;
            line-height: 2.5rem;
            letter-spacing: 0.034rem;
            font-family: $font-sofia-pro-semi-bold;
            font-size: 0.75rem;
            font-weight: bold;
            color: red;

            @media only screen and (max-width: $screen-sm-max) {
              font-size: 0.7rem;
            }
          }

          .more-questions-container {
            padding-top: 1.5rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            @media only screen and (min-width: $screen-sm) {
              justify-content: space-between;
            }

            .more-questions-input,
            .more-questions-input-message {
              font-family: $font-sofia-pro-semi-bold;
              font-size: 1rem;
              font-weight: bold;
              padding: 0.938rem 1.75rem;
              color: $c-grey-600;
              min-width: 36%;
              background-color: $c-deep-purple-350;
              border-radius: 0.5rem;
              border: 0.125rem solid $c-deep-purple-300;
              margin-bottom: 2rem;
              outline: none;

              @media only screen and (max-width: $screen-xs-max) {
                min-width: 84%;
                margin-left: 0;
                margin-bottom: 1.875rem;
              }

              @media only screen and (max-width: 425px) {
                min-width: 81%;
                margin-left: 0;
                margin-bottom: 1.875rem;
              }

              &::placeholder {
                color: $c-grey-600;
                font-size: 1rem;

              }

              &:hover,
              &:focus,
              &:active {
                border-color: $c-grey-550;
              }
            }

            .questions-input-container {
              display: flex;
              flex-direction: column;
              width: 18rem;
              margin-bottom: 2.5rem;

              @media only screen and (max-width: 1400px) {
                width: 100%;
                margin-bottom: 1.5rem;
              }

              .more-questions-input {
                height: 2.81rem;
                margin-bottom: 0;
                border: 1px solid #9A9A9A;
                padding: 0 0 0 1.25rem;
                box-sizing: border-box;

                @media only screen and (max-width: $screen-sm) {
                  font-size: 0.875rem;
                }
              }

              .more-questions-input:active,
              .more-questions-input:hover {
                border-color: #f37ea1;
              }

              .error {
                border-color: red;
              }
            }

            .more-questions-input,
            .more-questions-input-message {
              background-color: #FFFFFF;

              &:hover {
                border-color: rgba(72,68,162,0.501961);
              }

            }

            .more-questions-input-message {
              width: 100%;
              height: 18rem;
              border: 1px solid #9A9A9A;
              padding: 1rem;
              margin-bottom: 1.5rem;

              @media only screen and (min-width: $screen-sm) {
                min-width: fit-content;
              }

              @media only screen and (max-width: $screen-sm) {
                font-size: 0.875rem;
              }
            }

            .more-questions-input-message:active,
            .more-questions-input-message:hover {
              border-color: #f37ea1;
            }

            .error {
              color: red;
              border-color: red;
            }
          }

          h3 {
            padding-top: 7.81rem;

            @media only screen and (max-width: $screen-sm) {
              padding-top: 0;
            }
          }
        }
      }
    }
  }

  .landing-contacts-container:before {
    @media only screen and (min-width: $screen-sm) {
      content : "";
      position: absolute;
      left: 50%;
      height  : 1px;
      width   : 50%;
      border-top: 1px solid #DCDCDC;
    }
  }

  .landing-contacts-container:after {
    @media only screen and (min-width: $screen-sm) {
      content : "";
      position: absolute;
      left: 50%;
      height: 1px;
      width: 50%;
      border-bottom: 1px solid #DCDCDC;
    }
  }

  .separator {
    display: block;
    border: 0;
    border-top: 1px solid #CCCCCC;
    margin-top: 6.375rem;

    @media only screen and (min-width: $screen-sm) {
      display: none;
    }
  }

  .hide {
    height: 210px;

    @media only screen and (min-width: 580px) {
      height: auto;
    }
  }

  .counters-description-container {
    overflow: hidden;
    position: relative;

    @media only screen and (min-width: 580px) {
      overflow: auto;
    }
  }

  .counters_toggle {
    font-size: 1rem;
    font-family: $font-ttnorms-medium;
  }

  .counters_toggle {
    @media only screen and (min-width: 580px) {
      display: none;
    }
  }

  .cookie-banner-container {
    .cookie-inner-container {
        padding: 0.81rem 1.875rem 0.68rem 1.875rem;

        @media only screen and (max-width: $screen-md) {
          padding: 0.81rem 1.25rem 1.125rem 1.25rem;
        }

      button {
        p {
          color: #FFFFFF;
        }
      }
    }
  }

  .hidden-phone {
    display: none !important;
  }
}
