// -----------------------------------------------------------------------------
//  Variables
// -----------------------------------------------------------------------------
//  01. Colors
// -----------------------------------------------------------------------------

//  01. Colors
$c-white: #fff;
$c-black: #000;

$c-grey-300: #5f5e74;
$c-grey-350: #D4DBF140;
$c-grey-370: #474747;
$c-grey-400: #24224082;
$c-grey-450: #242240;
$c-grey-470: #4C4C64;
$c-grey-500: #393853;
$c-grey-550: #4844A280;
$c-grey-600: #1f1e3d66;
$c-grey-650: #22508A0A;

$c-mauvelous: #f37ea1;
$c-mauvelous-dark: #ef5a8682;
$c-deep-purple-50: #ECEFF8;
$c-deep-purple-100: #E7EBFF;
$c-deep-purple-150: #D7DAED;
$c-deep-purple-200: #D7DBF2;
$c-deep-purple-250: #D3D5E8;
$c-deep-purple-300: #E9E7F7;
$c-deep-purple-350: #F2F1FA;
$c-deep-purple-400: #E6E1F4;
$c-deep-purple-450: #9897b3;
$c-deep-purple-500: #5C5CAE;
$c-deep-purple-700: #66667B;
$c-deep-purple: #7b77d1;
$c-deep-purple-light: #7b77d1B3;

$c-green-500: #4CAF50;
$c-red-600: #E53935;

$text-color: rgb(69,69,69);
$small-title-color: rgb(25,25,25);
