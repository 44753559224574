// -----------------------------------------------------------------------------
//  Promo > Buttons
// -----------------------------------------------------------------------------
//    01. Base styles
//    02. Primary Button (Pink)
//    03. Secondary Button (White)
//    04. Learn more Button
//    05. Language change Button
//    06. Header menu
// -----------------------------------------------------------------------------

//    01. Base styles
.button {
  font-family: $font-sofia-pro-semi-bold;
  text-transform: uppercase;
  cursor: pointer;
  padding: 1.25rem;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 20;

  &:hover {
    text-decoration: none;
    color: inherit;
  }

  &:focus {
    outline: 0;
  }

  &.small {
    font-size: 0.688rem;
    letter-spacing: 0.034rem;
    min-width: 12.5rem;
    padding: 0.125rem;
    line-height: 2.5rem;
  }

  @media only screen and (max-width: $screen-xs-max) {
    padding: 1rem 0;
    width: auto;
  }
}

//    02. Primary Button (Pink)
.button-primary,
.button-personal-account {
  background-color: $c-mauvelous;
  border: 1px solid $c-mauvelous-dark;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 1.063rem $c-mauvelous-dark;
  color: $c-white;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.044rem;
  text-decoration: none;
  position: relative;
  width: fit-content;
  min-width: 13.25rem;
  overflow: hidden;

  @media only screen and (max-width: $screen-xs-max) {
    font-size: 0.813rem;
    width: 100%;
  }

  &:hover {
    text-decoration: none;
    color: inherit;

    p {
      transition: all .55s ease-in-out;
    }

    .button-circle {
      width: 200%;
      height: 500%;
      opacity: 1;
      top: -70px;
      left: -70px;
      transition: all .6s ease-in-out;
    }
  }

  p {
    color: $c-white;
    position: relative;
    margin: 0;
  }

  .button-circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    background: #E16A8D;
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.button-personal-account {
  margin: 0 1.625rem;

  @media only screen and (max-width: $screen-sm-max) {
    font-size: 0.875rem;
    margin: 0;
    text-align: center;
  }
}

//    03. Secondary Button
.button-secondary,
.button-gateways-map,
.button-software {
  border: 1px solid $c-mauvelous-dark;
  border-radius: 0.5rem;
  color: $c-mauvelous;
  opacity: 1;
  font-family: $font-sofia-pro-semi-bold;
  font-size: 0.875rem;
  font-weight: 600;
  margin: 0;
  letter-spacing: 0.044rem;
  position: relative;
  width: fit-content;
  min-width: 13.25rem;
  overflow: hidden;

  &:hover {
    text-decoration: none;
    color: inherit;

    p {
      color: $c-white;
      transition: all .55s ease-in-out;
    }

    .button-circle {
      width: 200%;
      height: 500%;
      opacity: 1;
      top: -70px;
      left: -70px;
      transition: all .6s ease-in-out;
    }
  }

  p {
    color: #f37ea1;
    text-decoration: none;
    position: relative;
    margin: 0;
  }

  .button-circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    background: $c-mauvelous;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  @media only screen and (max-width: $screen-sm-max) {
    font-size: 1.375rem;
    font-weight: 600;
  }

  @media only screen and (max-width: $screen-xs-max) {
    font-size: 0.813rem;
    font-weight: 600;
  }
}

.button-software {
  width: fit-content;

  @media only screen and (max-width: $screen-sm-max) {
    width: 100%;
  }
}

// 04. Learn more Button

.learn-more-button {
  display: flex;
  align-items: center;
  font-family: $font-sofia-pro-semi-bold;
  font-weight: 600;
  font-size: 0.875rem;
  text-transform: uppercase;

  p {
    color: $c-mauvelous;
    text-decoration: none;
    margin: 0;

    &.center-button {
      transition: margin 0.3s ease-in-out;
    }
  }

  div {
    background-color: transparent;
    background-image: url("/assets/images/learn_more_arrow.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 2rem;
    height: 2rem;
    margin-left: 0.5rem;
    transition: margin 0.3s ease-in-out;
  }

  &:hover,
  &:active {
    text-decoration: none;

    p {
      &.center-button {
        margin-left: 0.3rem;
        transition: margin 0.3s ease-in-out;
      }
    }

    div {
      margin-left: 0.8rem;
      transition: margin 0.3s ease-in-out;
    }
  }
}

// 05. Language change Button

.lang-button {
  display: flex;
  align-items: center;
  border: navajowhite;
  background-color: transparent;
  cursor: pointer;
  z-index: 20;
  font-family: $font-sofia-pro-semi-bold;
  font-weight: 700;
  text-transform: uppercase;
  color: $c-grey-500;
  padding: 0.2rem 0rem;
  margin: 0 0.5rem;
  border-bottom: 0.065rem solid transparent;

  &.safari {
    font-size: 0.875rem;
  }

  &:hover {
    border-bottom-color: #9393DF;
    color: #9393DF;
    opacity: 1;
  }

  &.active {
    border-bottom-color: #9393DF;
  }
}

// for mobile
.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
  z-index: 1;

  &:hover {
    .dropdown-content {
      opacity: 1;
    }
  }

  .dropdown-button {
    border: 0.125rem solid $c-mauvelous-dark;
    background-color: $c-white;
    border-radius: 0.5rem;
    color: #393853 !important;
    opacity: 0.9;
    font-size: 0.875rem;
    font-weight: bold;
    padding: 0.7rem 1.125rem;
    text-transform: uppercase;
    text-decoration: none;
    z-index: 1;

    @media only screen and (max-width: $screen-sm-max) {
      border: none;
      color: #393853 !important;
      background-color: transparent!important;
      padding: 0rem 1.125rem;
    }

    &:hover,
    &:active,
    &:focus {
      color: $c-white !important;
      background-color:  #f37ea1 !important;
      text-decoration: none;

      @media only screen and (max-width: $screen-sm-max) {
        background-color: transparent!important;
        color: #393853 !important;
        padding: 0rem 1.125rem;
      }
    }
  }

  .dropdown-content {
    position: absolute;
    opacity: 0;
    z-index: 1;
  }

  .button-locale {
    display: block;
    background-color: $c-white;
    border: 0.125rem solid $c-mauvelous-dark;
    border-radius: 0.5rem;
    color: #393853 !important;
    opacity: 0.9;
    font-size: 0.875rem;
    font-weight: bold;
    padding: 0.7rem 1.125rem;
    text-transform: uppercase;
    text-decoration: none;
    z-index: 1;

    @media only screen and (max-width: $screen-sm-max) {
      border: none;
      background-color: transparent!important;
      color: #393853 !important;
      padding: 0.5rem 1.125rem;
    }

    &:hover,
    &:active,
    &:focus {
      color: $c-white !important;
      background-color:  #f37ea1 !important;
      text-decoration: none;

      @media only screen and (max-width: $screen-sm-max) {
        background-color: transparent !important;
        color: #393853 !important;
      }
    }
  }
}

// 06. Header menu

.header-menu {
  position: relative;
  display: inline-block;
  cursor: pointer;
  z-index: 1;

  &:hover,
  &:active,
  &:focus {
    .dropdown-content {
      display: block;
    }

    .dropdown-button {
      .arrow {
        transform: rotate(360deg);
      }

      .menu-link {
        text-decoration: none;
        color: #9393DF;
      }
    }
  }

  .dropdown-button {
    background-color: transparent;
    border: none;
    z-index: 1;
    display: flex;
    align-items: center;
    padding: 0.625rem;

    .menu-link {
      color: #393853;
      font-size: 0.875rem;
      font-weight: bold;
      text-transform: uppercase;
      text-decoration: none;
    }

    .arrow {
      background-image: url('/assets/images/arrow_down.svg');
      background-repeat: no-repeat;
      background-size: cover;
      transform: rotate(180deg);
      height: 5px;
      width: 9px;
      margin: 0 0.5rem;
    }

    @media only screen and (max-width: $screen-sm-max) {
      color: #393853 !important;
    }

    &:hover,
    &:active,
    &:focus {
      @media only screen and (max-width: $screen-sm-max) {
        padding: 0.5rem 1.125rem;
      }
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    top: 2.45rem;
    z-index: 1;
    width: max-content;
  }

  .section-link-button {
    display: block;
    color: #393853 !important;
    opacity: 0;
    font-size: 0.875rem;
    font-weight: bold;
    background-color: $c-white;
    border-left: 2px solid #9393DF;
    border-right: 2px solid #9393DF;
    padding: 1.1rem 1.15rem;
    padding-right: 4rem;
    text-transform: uppercase;
    text-decoration: none;
    z-index: 1;

    &:first-child {
      border-top: 2px solid #9393DF;
      border-radius: 5px 5px 0 0;
    }

    &:last-of-type {
      border-bottom: 2px solid #9393DF;
      border-radius: 0 0 5px 5px;
    }

    @media only screen and (max-width: $screen-sm-max) {
      border: none;
      background-color: transparent!important;
      color: #393853 !important;
      padding: 0.5rem 1.125rem;
    }

    &:hover,
    &:active,
    &:focus {
      color: $c-white !important;
      background-color:  #9393DF !important;
      text-decoration: none;
      opacity: 1;

      @media only screen and (max-width: $screen-sm-max) {
        background-color: transparent !important;
        color: #393853 !important;
      }
    }
  }
}

.header-menu-mobile,
.header-menu-mobile-open {
  width: 100%;
  font-family: $font-sofia-pro-semi-bold;

  .dropdown-button {
    width: 100%;
    background-color: transparent;
    border: none;
    opacity: 0.9;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .menu-link {
      color: #393853;
      font-size: 1.125rem;
      font-weight: bold;
      text-transform: uppercase;
      text-decoration: none;
    }

    .arrow {
      background-image: url('/assets/images/arrow_down.svg');
      background-repeat: no-repeat;
      background-size: cover;
      transform: rotate(180deg);
      height: 7px;
      width: 13px;
      margin: 0 0.5rem;
    }

    @media only screen and (max-width: $screen-sm-max) {
      color: #393853 !important;
    }
  }

  .dropdown-content {
    display: none;
  }
}


.header-menu-mobile-open {
  .dropdown-button {
    .arrow {
      transform: rotate(360deg);
    }
  }

  .dropdown-content {
    display: block;
  }

  .section-link-button {
    display: block;
    color: #393853 !important;
    opacity: 0.9;
    font-family: $font-sofia-pro;
    font-size: 1.125rem;
    padding: 0.625rem 1.825rem;
    text-decoration: none;
    z-index: 1;

    @media only screen and (max-width: $screen-sm-max) {
      border: none;
      background-color: transparent!important;
      color: #393853 !important;
      padding: 0.5rem 1.825rem;
    }
  }
}

.dropdown {
  position: relative;
  perspective: 1000px;

  &:hover {
    .dropdown-menu li {
      display: block;
    }
  }
}

.dropdown-menu {
  position: absolute;

  p {
    display: none;
    opacity: 0;
    margin: 0;
  }

  @for $num from 1 through 6 {
    .dropdown-item-#{$num} {
      transform-origin: top center;
      animation: rotateX 300ms ($num * 60ms) ease-in-out forwards;
    }
  }
}

@keyframes rotateX {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }
  50% {
    transform: rotateX(-20deg);
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}
