// Software page
.about-meters-data {
  margin: 2.5rem 0 9.75rem;
  display: flex;
  position: relative;

  @media only screen and (max-width: $screen-sm-max) {
    flex-direction: column;
    margin: 1.75rem 0 4.5rem;
  }

  ul {
    margin: 0;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: $screen-sm-max) {
      flex-direction: row;
    }
  }

  .ui-tabs-tab {
    list-style: none;
    margin: 0 5.875rem 0 0;
    min-width: 10rem;

    @media only screen and (max-width: $screen-sm-max) {
      margin: 0 3rem 0 0;
      min-width: max-content;
    }

    .link {
      display: flex;
      align-items: center;
      font-family: $font-sofia-pro-semi-bold;
      font-size: 0.875rem;
      font-weight: bold;
      letter-spacing: 0.7px;
      color: #242240;
      text-transform: uppercase;
      opacity: 0.5;
      margin-bottom: 3.625rem;
      border-bottom: 2px transparent solid;

      @media only screen and (max-width: $screen-sm-max) {
        margin-bottom: 0.5rem;
      }

      p {
        border-bottom: 2px transparent solid;
        padding-bottom: 0.625rem;
        margin-bottom: 0rem;
      }

      div {
        border: none;
        background-color: $c-white;
        width: 20px;
        height: 20px;
        margin-bottom: 0.625rem;
        margin-left: 0.5rem;

        @media only screen and (max-width: $screen-sm-max) {
          display: none;
        }
      }
    }

    &.ui-state-active {
      .link {
        opacity: 1;
        border-bottom: transparent;
        border-bottom: 2px transparent solid;

        p {
          border-bottom: 2px #242240 solid;
          margin: 0;
          text-decoration: none;
        }

        div {
          background-color: transparent;
          background-image: url("/assets/images/software/arror_right.svg");
          background-position: center;
          background-repeat: no-repeat;

          @media only screen and (max-width: $screen-sm-max) {
            display: none;
          }
        }
      }
    }

    &.ui-state-hover:not(.ui-state-active) {
      .link {
        opacity: 1;
        text-decoration: none;
        border-bottom: 2px transparent solid;

        p {
          border-bottom: 2px transparent solid;
          margin: 0;
        }
      }
    }
  }

  .tabs-outer-wrapper {
    width: auto;
    padding: 0;
    margin: 0;

    .tabs-container {
      margin: 0;
      padding: 0;
    }
  }
}

.software-item-description {
  display: flex;

  @media only screen and (max-width: $screen-sm-max) {
    flex-direction: column-reverse;
  }

  .software-text {
    font-family: $font-name-ttnorms;
    font-size: 1rem;
    line-height: 1.6rem;
    color: #242240;
    margin-right: 5.875rem;
    min-width: 24rem;

    @media only screen and (max-width: $screen-sm-max) {
      margin-right: 0;
      min-width: auto;
    }
  }

  .software-image {
    width: 722px;
    height: 456px;
    box-shadow: 0px 0px 6px #00000033;
    border-radius: 1rem;

    @media only screen and (max-width: $screen-sm-max) {
      width: auto;
      height: auto;
      margin-bottom: 1.5rem;
    }
  }
}

.support-image {
  display: block;
  margin: 8.75rem auto 9.75rem;

  @media only screen and (max-width: $screen-sm-max) {
    display: none;
  }
}

.support-image-mobile {
  display: none;

  @media only screen and (max-width: $screen-sm-max) {
    display: block;
    margin: 4rem 0 1.2rem;
  }
}

.integration-page-wrapper {
  max-width: 86.25rem;
  margin: auto;

  @media only screen and (max-width: $screen-sm-max) {
    margin: 0;
  }
}

.integration-intro-wrapper {
  display: flex;
  margin: 3.75rem 0;

  @media only screen and (max-width: $screen-sm-min) {
    flex-direction: column;
    margin: 0 1.25rem 2.5rem;
  }
}

.integration-items-container {
  margin-bottom: 9.75rem;

  @media only screen and (max-width: $screen-sm-max) {
    margin-bottom: 4.5rem;
  }
}

.integration-item {
  display: flex;

  @media only screen and (max-width: $screen-sm-max) {
    flex-direction: column;
  }
}

.integration-image {
  width: 50%;
  border-radius: 1rem;

  @media only screen and (max-width: $screen-sm-max) {
    display: none;
  }
}

.integration-image-mobile {
  display: none;

  @media only screen and (max-width: $screen-sm-max) {
    display: block;
    width: 100%;
    margin-bottom: 1.5rem;
  }
}

.integration-text-container,
.integration-text-container-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2rem;

  @media only screen and (max-width: $screen-sm-max) {
    padding: 0 1.25rem;
  }

  .integration-item-title {
    font-family: $font-name-ttnorms-medium;
    font-size: 1.25rem;
    color: #242240;
    margin-bottom: 1rem;

    @media only screen and (max-width: $screen-sm-max) {
      font-size: 1.125rem;
    }
  }

  .integration-item-description,
  .integration-item-description-last {
    font-family: $font-name-ttnorms;
    font-size: 1rem;
    color: #242240;
    line-height: 1.6rem;
    margin: 0 0 2rem 0;
  }

  .integration-item-description-last {
    @media only screen and (max-width: $screen-sm-max) {
      margin: 0;
    }
  }
}

.integration-text-container-middle {
  padding: 0 2rem 0 0;

  @media only screen and (max-width: $screen-sm-max) {
    padding: 0 1.25rem;
  }
}

.questions-title-wrapper {
  margin: auto;
  max-width: 86.25rem;

  @media only screen and (max-width: $screen-sm-max) {
    margin: 0 1.25rem;
    margin-bottom: 1.5rem;
  }
}

.software-questions-wrapper {
  max-width: 86.25rem;
  margin: auto;

  @media only screen and (max-width: $screen-sm-max) {
    margin: 0;
  }
}

.software-questions-list {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  @media only screen and (max-width: $screen-sm-max) {
    margin-top: 0;
  }
}

.question-item {
  @media only screen and (max-width: $screen-sm-max) {
    padding: 0 1.25rem;
  }
}

.software-question-text {
  font-family: $font-name-ttnorms-medium;
  font-size: 1.25rem;
  line-height: 2rem;
  color: #242240;
  padding-right: 1.5rem;
  margin: 0;

  @media only screen and (max-width: $screen-sm-max) {
    line-height: 1.5rem !important;
  }
}

// Architecture page
.components-image {
  width: 50%;
  height: 50%;
  box-shadow: 0px 0px 6px #00000033;
  border-radius: 1rem;

  @media only screen and (max-width: $screen-sm-max) {
    display: none;
  }
}

.components-image-mobile {
  display: none;

  @media only screen and (max-width: $screen-sm-max) {
    display: block;
    width: auto;
    margin: 0 1.25rem 1.5rem;
    box-shadow: 0px 0px 6px #00000033;
    border-radius: 1rem;
  }
}

.microservices-wrapper {
  display: flex;
  margin: 1.5rem auto 9.75rem;

  img {
    width: 50%;
  }

  @media only screen and (max-width: $screen-sm-max) {
    flex-direction: column;
    margin: 1.5rem auto 3.75rem;

    img {
      width: 100%;
    }
  }
}

.security-description {
  line-height: 1.6rem;
  margin-bottom: 3.75rem;

  @media only screen and (max-width: $screen-sm-max) {
    margin-bottom: 2rem;
  }
}

.data-handling-services {
  margin: 2.5rem 0 9.75rem;
  display: flex;
  flex-direction: column;
  position: relative;

  @media only screen and (max-width: $screen-sm-max) {
    flex-direction: column;
    margin: 1.75rem 0 4.5rem;
  }

  ul {
    margin: 0;
    display: flex;
  }

  .grey-border {
    border-top: 1px solid #CCCCCC;
    margin-bottom: 1.68rem;

    @media only screen and (max-width: $screen-sm-min) {
      border: transparent;
    }
  }

  .ui-tabs-tab {
    list-style: none;
    margin: 0;

    .link {
      display: flex;
      align-items: center;
      font-family: $font-sofia-pro-semi-bold;
      font-size: 0.875rem;
      font-weight: bold;
      letter-spacing: 0.7px;
      color: #242240;
      text-transform: uppercase;
      padding: 1rem 0;
      margin: 0 2rem 3.625rem 0;
      border-bottom: 3px transparent solid;

      @media only screen and (max-width: $screen-sm-max) {
        margin-bottom: 0.5rem;
      }

      p {
        opacity: 0.5;
        margin-bottom: 0rem;
      }
    }

    &.ui-state-active {
      .link {
        border-bottom: 3px #242240 solid;

        p {
          opacity: 1;
          margin: 0;
          text-decoration: none;
        }
      }
    }

    &.ui-state-hover:not(.ui-state-active) {
      .link {
        text-decoration: none;
        border-bottom: 3px transparent solid;

        p {
          opacity: 1;
          margin: 0;
        }
      }
    }
  }

  .tabs-outer-wrapper {
    position: relative;
    width: auto;
    padding: 0;
    margin: 0;

    @media only screen and (max-width: $screen-sm-max) {
      margin: 0 1.25rem 1rem;
    }

    .tabs-wrapper {
      display: flex;
      justify-content: flex-start;
    }

    .tabs-container {
      padding: 0;
      margin: 0;
    }

    .left-arrow,
    .right-arrow {
      position: absolute;
      top: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2rem;
      width: 4rem;
      flex: 0 0 2rem;
      z-index: 2;
    }

    .left-arrow {
      left: 0rem;
      background: none;

      @media only screen and (max-width: $screen-sm-max) {
        background: linear-gradient(90deg, rgba(253, 253, 253, 1) 0%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0) 100%);
      }
    }

    .right-arrow {
      right: 0rem;
      background: none;

      @media only screen and (max-width: $screen-sm-max) {
        background: linear-gradient(90deg, rgba(253, 253, 253, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 1) 100%);
      }
    }
  }
}

.menu-border {
  position: absolute;
  top: 53px;
  width: 100%;
  height: 0;
  border-bottom: 1px #cccccc solid;
  z-index: -1;

  @media only screen and (max-width: $screen-sm-max) {
    width: 89%;
    margin: 0 1.25rem;
  }
}

.data-handling-page-wrapper {
  max-width: 86.25rem;
  margin: auto;

  @media only screen and (max-width: $screen-xs-max) {
    margin: 0;
  }
}

.data-handling-item {
  display: flex;

  @media only screen and (max-width: $screen-sm-max) {
    flex-direction: column;
  }

  .data-handling-image {
    width: 50%;
    height: auto;
    box-shadow: 0px 0px 6px #00000033;
    border-radius: 1rem;

    @media only screen and (max-width: $screen-sm-max) {
      width: auto;
      height: auto;
      border-radius: 1rem;
      margin: 0 1.25rem 1.5rem;
    }
  }

  .data-handling-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 2rem;

    @media only screen and (max-width: $screen-sm-max) {
      padding: 1.5rem 1.25rem 0;
    }
  }
}
